import {
    findToken,
    getUserData,
    login, register, resetPassword, sendReset, verify,updateProfile
} from '@/services/auth'
import {CLIENT_ID, CLIENT_SECRET, GRANT_TYPE,} from "../../../env";
import client from "@/store/api-services/client";

export  const auth = {
    namespaced:true,
    state:{
        userData:undefined,
        countries:undefined,
    },
    mutations: {
        SET_USER_DATA(state,payload){
            state.userData = {...payload}
        },
        SET_COUNTRIES(state,payload){
            state.countries = payload
        },
    },
    actions: {
        login({commit},data){
            return new Promise((resolve,reject)=>{
                data.append('grant_type',GRANT_TYPE)
                data.append('client_id',CLIENT_ID)
                data.append('client_secret',CLIENT_SECRET)
                login(data).then((response)=>{
                    localStorage.setItem("token",response.data.access_token);
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        register({commit},data){
            return new Promise((resolve,reject)=>{
                register(data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        resetPassword({commit},data){
            return new Promise((resolve,reject)=>{
                resetPassword(data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        verify({commit},data){
            return new Promise((resolve,reject)=>{
                verify(data).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        find({commit},data){
            return new Promise((resolve,reject)=>{
                findToken(data).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        sendResetMail({commit},data){
            return new Promise((resolve,reject)=>{
                sendReset(data).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getUserData({commit}){
            return new Promise((resolve,reject)=>{
                getUserData().then((response)=>{
                    commit('SET_USER_DATA',response.data)
                    commit('SET_COUNTRIES',response.data.countries)
                    resolve(response.data)
                }).catch((err)=>{
                    reject(err)
                })
            })
        },
        updateProfile({commit},data){
            return new Promise((resolve,reject)=>{
                updateProfile(data).then((response)=>{
                    resolve(response.data)
                }).catch((err)=>{
                    reject(err)
                })
            })
        },
        logout({commit}) {
            let url = 'logout';
            return new Promise((resolve, reject) => {
                client({
                    method: 'get',
                    url: url
                }).then((response) => {
                    localStorage.clear();
                    location.reload();
                }).catch((error) => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        user(state) {
            return state.userData;
        }
    }
}
