<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
            <div class=" col-md-12 col-lg-12 col-xl-12 order-0 mb-4">
                <div class="card-title mb-0">
                  <div class="row bg-white p-2 mb-4" style="border-radius: 5px">
                    <h5>Statistiques globales</h5>
                    <div class="col-md-3">
                      Nb d'entreprises : {{data.total_enterprise}}
                    </div>
                    <div class="col-md-3">
                      Nb Total de vente : {{data.total_sells}}
                    </div>
                    <div class="col-md-3">
                      Total de vente : {{data.total_sells_amount}}
                    </div>
                    <div class="col-md-3">
                      Solde Total : {{data.solde}}
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import globalMixins from "@/mixins/globalMixins";

    export default {
        name: "MasterAdminDashboard",
      mixins:[globalMixins()],
        computed:{
           ...mapState('dashboard',['data'])
        },
        mounted() {
            this.$store.dispatch('dashboard/get').then(()=>{})
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>