<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card">
                    <div class="card-body p-4">
                        <h4 class="mb-3 f-w-400 text-center">CONNEXION</h4>
                        <div class="p-2 mt-4">
                            <div v-if="loginError" >
                                <div class="alert alert-danger text-center mb-4" role="alert">
                                    {{loginError}}
                                </div>
                            </div>
                            <div v-if="validationErrors">
                                <div class="alert alert-danger justify-content-start">
                                    <ul class="list-unstyled text-center">
                                        <li v-for="errors in validationErrors.errors">
                                            <ul class="list-unstyled">
                                                <li v-for="message in errors">{{message}}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <v-form ref="form" v-model="formIsValid">
                                <v-row>
                                    <v-text-field
                                                  solo
                                                  :rules="[rules.required,rules.email_rule]"
                                                  v-model="email"  label="Email"  />
                                </v-row>
                                <v-row>
                                    <v-text-field
                                            :rules="[rules.required,rules.min]"
                                            v-model="password"
                                            label="Mot de passe"
                                            :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                                            @click:append="showPass=!showPass"
                                            :type="showPass ? 'text' : 'password'"
                                            solo
                                    />
                                </v-row>
                                <div  style="text-align:center;margin-top: 15px"  class="field-wrapper">
                                    <v-btn
                                            class="ma-2"
                                            :loading="submitInProgress"
                                            :disabled="submitInProgress || !formIsValid"
                                            color="primary"
                                            @click.prevent="submitForm">
                                        Se Connecter
                                    </v-btn>
                                </div>
                            </v-form>
                            <div class="text-center">
                                <p class="mb-0 text-muted">Mot de passe oublié? <router-link class="f-w-400" :to="{name:'password.forgot'}">Réinitialiser </router-link> | <router-link class="f-w-400" :to="{name:'register'}">Inscription </router-link></p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- end row -->
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import ValidationErrors from "@/components/ValidationErrors";
    export default {
        name: "Login",
        components: {ValidationErrors},
        data() {
            return {
                showPass:false,
                formIsValid:false,
                email: null,
                password: null,
                errors: {},
                submitInProgress: false,
                validationErrors: undefined,
                loginError: undefined,
                rules: {
                    required: v => !!v ||  "Ce champ est requis",
                    min: v => !!v && v.length >= 6 || "Le mot de passe doit contenir au moins 6 caractères",
                    email_rule: v =>  !!v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  "Email invalide",
                },
            }
        },
        computed:{
            ...mapGetters('auth/',['user']),
            ...mapState('auth/',['user']),

        },
        mounted(){
        },
        methods: {
            submitForm () {
                let data = new FormData();
                data.append("username",this.email);
                data.append("password",this.password);
                    this.submitInProgress = true;
                    this.$store.dispatch("auth/login", data).then(() => {
                        window.location = "/dashboard";
                    }).catch((error)=>{
                        this.submitInProgress = false;
                        switch (error.response.status) {
                            case 401 :
                                this.loginError = error.response.data.message || "Email ou mot de passe incorrect";
                                this.toast("error", this.loginError)
                                break;
                                case 406 :
                                this.toast("error", error.response.data.message)
                                break;
                            case 422:
                                this.validationErrors = error.response.data
                                break;
                        }
                    });
            },
        },
    }
</script>

<style scoped>

</style>