<template>
  <div class="row">
      <div v-if="menu.roles.includes(getUserFullRole())" class="col-4" v-for="(menu, index) in menus" :key="'menu-'+index">
        <span >
          <router-link class="card p-2"  :to="{name:menu.link}">
            <v-img :src="`assets/img/icons/${menu.image}`" alt="" width="50" style="margin: auto" />
            <span class="mt-2 text-center">{{menu.label}}</span>
          </router-link>
        </span>

      </div>
  </div>
</template>

<script>
export default {
  name: "DashboardMenu",
  data (){
    return{
      menus : [

        {
          link : 'customers.index',
          image : 'supporter.png',
          label : 'Clients',
          roles : ['seller','client']
        },
        {
          link : 'games.played',
          image : 'travail-en-equipe.png',
          label : 'Ventes',
          roles : ['seller','client']
        },
        {
          link : 'tirages',
          image : 'tirage-au-sort.png',
          label : 'Tirages',
          roles : ['seller','client']
        },
        {
          link : 'gains.index',
          image : 'taux-de-croissance.png',
          label : 'Gains',
          roles : ['seller','client']
        },
        {
          link : 'games.index',
          image : 'lottery.png',
          label : 'Jeux',
          roles : ['client']
        },
        {
          link : 'games.play',
          image : 'jeux-de-societe.png',
          label : 'Jouer',
          roles : ['seller']
        },
        {
          link : 'profile',
          image : 'account.png',
          label : 'Paramètre',
          roles : ['client']
        },
        {
          link : 'configs',
          image : 'integrated-system.png',
          label : 'Configuration',
          roles : ['client']
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>