<template>
    <div>
        <ball-range-selector/>
        <div class="row mt-2" style="align-items: center">
            <div class="d-flex justify-content-sm-start justify-content-md-center games-container">
                <v-btn v-for="ball in ballsType==='balls'?currentBalls:pLotto4SelectedBalls" :key="'selected-f-ball-'+ball"
                       class="ma-2"
                       small
                       :outlined="ball.toString()!=lotto4Ball.first"
                       fab
                       @click="combine({type:'lotto4',ball:ball,position:'first',gameId:gameId})"
                       color="primary">
                    {{ball}}
                </v-btn>
            </div>
            <div class="d-flex justify-content-sm-start justify-content-md-center games-container">
                <v-btn v-for="ball in ballsType==='balls'?currentBalls:pLotto4SelectedBalls" :key="'selected-s-ball-'+ball"
                       class="ma-2"
                       small
                       :outlined="ball.toString()!=lotto4Ball.second"
                       fab
                       @click="combine({type:'lotto4',ball:ball,position:'second',gameId:gameId})"
                       color="primary">
                    {{ball}}
                </v-btn>
            </div>
            <div class="col-md-1  mt-4">
                <v-btn small color="primary"
                       v-if="lotto4Ball.second.length > 0  && lotto4Ball.first.length > 0"
                       @click="combine({type:'lotto4',gameId:gameId})">
                    combiner
                </v-btn>
            </div>
        </div>
        <div class="d-flex justify-content-start games-container"
             v-if="lotto4Balls.length>0">
            <div v-for="ball in lotto4Balls" :key="'lotto_4-'+ball"
                 @click="removeCombination({type:'lotto4',ball:ball})">
                <lotto4 :balls="ball"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import Lotto4 from "@/components/Lotto4";
    import BallRangeSelector from "@/components/balls/BallRangeSelector.vue";

    export default {
        name: "Lotto4Selection",
        props:['type','gameId'],
        components: {BallRangeSelector, Lotto4},
        computed: {
            ...mapState('balls', [
                'selectedBalls',
                'pLotto4SelectedBalls',
                'currentBalls',
                'lotto4Ball',
                'lotto4Balls',
            ]),
            ballsType(){
                return this.type?this.type:'balls'
            }
        },
        methods: {
            ...mapActions('balls', [
                'removeCombination',
                'combine',
            ])
        }
    }
</script>

<style scoped>

</style>