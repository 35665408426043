import {
    FIND_TOKEN,
    GET_USER_INFOS,
    LOGIN,
    LOGOUT,
    PASSWORD_FORGOT,
    REGISTER,
    RESET_PASSWORD, UPDATE_PROFILE,
    VERIFY,
} from "@/services/apis";
import authClient from "@/store/api-services/auth-client";
import client from "@/store/api-services/client";

export const login = (data)=>{
    return new Promise((resolve,reject)=>{
        authClient({
            method : 'POST',
            url:LOGIN,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const register = (data)=>{
    return new Promise((resolve,reject)=>{
        authClient({
            method : 'POST',
            url:REGISTER,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const resetPassword = (data)=>{
    return new Promise((resolve,reject)=>{
        authClient({
            method : 'POST',
            url:RESET_PASSWORD,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const verify = (token)=>{
    return new Promise((resolve,reject)=>{
        authClient({
            method : 'GET',
            url:VERIFY+token,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const findToken = (token)=>{
    return new Promise((resolve,reject)=>{
        authClient({
            method : 'GET',
            url:FIND_TOKEN+token,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const sendReset = (data)=>{
    return new Promise((resolve,reject)=>{
        authClient({
            method : 'POST',
            url:PASSWORD_FORGOT,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const getUserData = ()=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:GET_USER_INFOS,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const  updateProfile = (data)=>{
    return new Promise((resolve, reject) => {
        client({
            method: 'POST',
            url: UPDATE_PROFILE,
            data:data
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error)
        })
    })
}
export const  logout = ()=>{
    return new Promise((resolve, reject) => {
        client({
            method: 'GET',
            url: LOGOUT
        }).then((response) => {
            localStorage.clear();
            location.reload();
        }).catch((error) => {
            reject(error)
        })
    })
}