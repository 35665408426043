import {edit, get, create, store, update, destroy, updateFormData} from "@/services/resources";
import {PLAYEDS} from "@/services/apis";

export  const playeds = {
    namespaced:true,
    state:{
        playeds:undefined,
    },
    mutations: {
        SET_PLAYEDS(state,payload){
            state.playeds = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        get({commit},data){
            return new Promise((resolve,reject)=>{
                store("played-index",data).then((response)=>{
                    commit('SET_PLAYEDS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit}){
            return new Promise((resolve,reject)=>{
                create(PLAYEDS).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(PLAYEDS,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(PLAYEDS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update({commit},data){
            return new Promise((resolve,reject)=>{
                update(PLAYEDS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy({commit},itemId){
            return new Promise((resolve,reject)=>{
                destroy(PLAYEDS,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
