<template>

  <v-app  class="authentication-bg" v-if="$route.meta.isAuth">
    <div class="account-pages my-5  pt-sm-5">
      <router-view/>
    </div>
  </v-app>
  <v-app id="app" v-else>
    <v-main class="home">
      <!-- Layout wrapper -->
      <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
          <!-- Menu -->
          <rigth-sidebar/>
          <!-- / Menu -->
          <!-- Layout container -->
          <div class="layout-page">
            <!-- Navbar -->
            <navbar/>
            <!-- / Navbar -->
            <!-- Content wrapper -->
            <div class="content-wrapper">
              <!-- Content -->
                <transition name="slide-in">
                    <router-view/>
                </transition>

              <!-- / Content -->
              <!-- Footer -->
              <app-footer/>
              <!-- / Footer -->
              <div class="content-backdrop fade"></div>
            </div>
            <!-- Content wrapper -->
          </div>
          <!-- / Layout page -->
        </div>

        <!-- Overlay -->
        <div class="layout-overlay layout-menu-toggle"></div>
      </div>
      <!-- / Layout wrapper -->
    </v-main>

  </v-app>
</template>

<script>
  import Navbar from "./views/layouts/Navbar";
  import RigthSidebar from "./views/layouts/RigthSidebar";
  import Users from "./views/Accounts/Users";
  import AppFooter from "./views/layouts/AppFooter";
  import {mapState} from "vuex";
  export default {
    components: {AppFooter, RigthSidebar, Navbar, Users},
    computed:{
      ...mapState('auth',['userData'])
    },
    mounted() {
    },
    beforeCreate() {
      if (!this.$route.meta.isAuth){
        this.$store.dispatch("auth/getUserData")
      }
    },
    beforeMount(){
      if (!this.$route.meta.isAuth) {
        this.$store.dispatch("auth/getUserData").then(() => {
          let routePermission = this.$route.meta.permission
          let userPermissions = this.userData.permissions
          if (!userPermissions.includes(routePermission)) {
           window.location = "/dashboard";
          }
        })
      }


    }
  }
</script>

<style>
.games-container{
  overflow-x: scroll;
}
::-webkit-scrollbar {
  height: 4px;              /* height of horizontal scrollbar ← You're missing this */
  width: 4px;               /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
  display: none;
}

  .menu-vertical .menu-inner > .menu-item .menu-link {
    margin: 0 2rem 0 1rem;
  }
  .tel-input{
   /* background-color: transparent;
    border-style: none;*/
  }



  .v-input .v-label{
    line-height: 20px  !important;
  }
  .v-overflow-btn .v-select__slot .v-label{
    height: 30px!important;
    line-height: 40px  !important;
  }
  .swal2-container{
    z-index:999999999!important;
  }


  .vs__dropdown-toggle{
    background: #fff;
    height: 40px;
    border: 1px solid #29b3a6;
  }
  body{
    font-size: 14px!important;
  }
  h3, .h3{
    font-size: 1.13rem  !important;
  }
  #menuNav{
    overflow: scroll !important;
    -ms-overflow-style: none  !important;  /* IE and Edge */
    scrollbar-width: none !important;
    height: 60vh;
  }
  #menuNav::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  .theme--light.v-toolbar.v-sheet{
    background: #f8f8f8!important;
  }
  .v-card.v-sheet.theme--light{
    background: #f8f8f8!important;

  }
  .container-scroller{
    overflow-x: hidden;
  }


  .loader_panel_component{
    margin-top: 15%;
  }
  .loader_panel_component img{
    width: 150px;
  }
  .loader_panel_component span{
    margin-top: 10px;
    font-weight: 500;
    font-size: 14pt;
  }
  .background-linear{
    background-image: linear-gradient(#f8f8f8, #ff6a2f) !important;
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    white-space: nowrap;
  }
  td{
    white-space: nowrap;
  }
  .v-chip.v-size--default{
    height: 23px !important;
  }
  .theme--light.v-input input{
    background: transparent;

  }
  #app{
    background: #F6F6F6;
  }

  @media (max-width: 991px){
    .navbar {
      right: 0;
    }
  }
  .navbar .navbar-menu-wrapper{
    width: 100%;
    padding: 0;
  }
  .v-application{
    font-family: 'Josefin Sans', sans-serif;
  }


  .btn-primary:hover,  .btn-primary:active{
    background: #ff6a2f;
    border: 1px solid #ff6a2f;
  }
  *:focus {
    outline: none;
  }
  .enter-btn{
    width: 100%;
    border-radius: 6px;
    padding: 13px 25px;
    color: rgba(255, 146, 47, 1);
    background: #f8f8f8;
    border: 1px solid rgba(255, 146, 47, 1);
    cursor: pointer;
  }
  .enter-btn:hover,.enter-btn:active,.enter-btn:focus{
    color: #fff;
    background: rgba(255, 146, 47, 1);
    border: 1px solid rgba(255, 146, 47, 1);
  }

  .v-application .primary{
    background: #ff6a2f;
  }
  .v-application .elevation-7,.v-application .elevation-1{
    box-shadow: none !important;
  }
  .v-sheet.v-card:not(.v-sheet--outlined),.v-sheet.v-toolbar:not(.v-sheet--outlined),.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
    box-shadow:none !important;
  }
  .theme--light.v-overflow-btn.theme--light.v-overflow-btn > .v-input__control > .v-input__slot{
    border: 1px solid #f8f8f8;
  }
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
    box-shadow: none !important;
    /* border-radius: 50px;*/
    background: #ff6a2f;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .v-card__title{
    /*text-transform: uppercase;*/
    font-weight: bold;
  }
  .v-data-table__wrapper tr{
    border-top: 10px solid #f8f8f8 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .v-data-table__wrapper tbody{
    border-bottom: 10px solid #f8f8f8 !important;

  }
  .v-data-table-header,.background-tb{
    background: #ff6a2f !important;
    color: #fff !important;
    border-radius: 50px !important;

  }

  table {
    border-collapse: collapse !important;
    border-radius: 1em !important;
    overflow: hidden !important;
  }

  th{
    color: #fff !important;
  }
  .background-tb{
    margin-bottom: 0 !important;
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
    border: none;
    background-color: rgba(204, 204, 204, 0.54);
  }
  .vue-tel-input{
    border: none;
    background-color: rgba(204, 204, 204, 0.54);
    border-radius: 5px;
    margin-top: -2px!important;

  }
  .v-application .headline{
    font-weight: bold;
    font-family: 'Josefin Sans', sans-serif !important;
  }
  .v-application .float-right{
    font-size: 10pt !important;
  }
  .sidebar-dark .sidebar .nav .nav-item.active > .nav-link{
    background: #ff6a2f;
  }
  .v-application--is-ltr .v-input--selection-controls__input{
    margin-right: 0;
  }
  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
    top: 5px;
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    color: #fff;
    font-family: 'Josefin Sans', sans-serif;
  }
  .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon{
    color: #fff !important;
  }
  .v-tabs-items{
    padding: 0 !important;
  }
  .v-sheet.v-toolbar:not(.v-sheet--outlined), .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{

    border-radius: 6px;

  }
  .v-card > *:last-child:not(.v-btn):not(.v-chip){
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .user_name{
    font-size: 13pt;font-weight: 600;
  }
  .v-toolbar__content{
    height: 90px !important;
  }
  .v-tab--active{
    background: rgb(255, 146, 47);
    color: #fff !important;
  }
  .v-tab{
    border-radius: 0 !important;
  }
  .theme--light.v-tabs > .v-tabs-bar{
    border-bottom: 5px solid #f8f8f8;
  }
  .img-profil{
    width: 50px;
    height: 50px;
  }
  .language-text{
    display: inline-block;
  }
  @media (max-width: 991px) {
    #beginContent{
      margin-top: 40px!important;
    }
    .language-text{
      display: none;
    }
    .v-tab{
      font-size: 7pt;
    }
    .user_name{
      font-size: 9pt;font-weight: 500
    }


    .content-wrapper{
      padding-top: 0;
    }
  }
  .v-toolbar__content .v-btn--contained{
    margin-bottom: 40px !important;
    /*border-radius: 50px !important;*/
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(55, 55, 55, 0.8) !important;
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0.2) !important;
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0) !important;
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(55, 55, 55, 0.8) !important;
      box-shadow: 0 0 0 0 rgba(55, 55, 55, 0.8) !important;
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(55, 55, 55, 0.4) !important;
      box-shadow: 0 0 0 10px rgba(55, 55, 55, 0.4) !important;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0) !important;
      box-shadow: 0 0 0 0 rgba(204,169,44, 0) !important;
    }
  }

  .pulse
  {
    animation: pulse 1s infinite !important;
  }
  .home_top_number{
    font-weight: 500 !important;

  }

  .small-img{
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

   .menu-inner{
    margin-left: -35px !important;
  }

</style>
