import client from "@/store/api-services/client";

export const get = (module)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}
export const create = (module)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module+'/create',
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}

export const show = (module,id)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module+'/'+id,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}

export const edit = (module,cityId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:module+`/${cityId}/edit`,
        }).then(response => resolve(response))
            .catch((error)=>reject(error.response))
    })
}

export const store = (url,data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:url,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const completeProduct = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"complete/products",
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const setGainAsPaid = (id)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'GET',
            url:`gains/${id}`,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const updateFormData = (module,data)=>{
    let requestData = data.id?data.data:data;
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:module+'/'+data.id,
            data:requestData
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const validateTirage = (data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'POST',
            url:"validate-tirages"+'/'+data.id,
            data:data
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}

export const update = (module,data)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:module+"/"+data.id,
            data:data
        }).then(response => resolve(response))
            .catch(error=>reject(error))
    })
}

export const destroy = (module,cityId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'DELETE',
            url:module+'/'+cityId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}
export const disableUser = (userId)=>{
    return new Promise((resolve,reject)=>{
        client({
            method : 'PUT',
            url:"users/disable/"+userId,
        }).then(response => resolve(response))
            .catch((error)=>reject(error))
    })
}