<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="">
                    <v-progress-linear
                            v-if="submitInProgress"
                            indeterminate
                            color="primary"
                    ></v-progress-linear>

                    <div>
                        <h4 class="mb-3 f-w-400 text-center" v-if="$route.name==='invitation'">Configuration du mot de passe</h4>
                        <h4 class="mb-3 f-w-400 text-center" v-else>Réinitialisation du mot de passe</h4>
                    </div>
                    <br><br>
                    <validation-errors v-if="validationErrors" :error="validationErrors"/>

                    <div v-if="verified || $route.name==='password.reset'" class="card-body p-4 d-flex justify-content-center">
                       <div class="p-2 mt-4">
                               <v-form ref="form" v-model="formIsValid">
                                   <v-row>
                                       <v-text-field
                                               :rules="[rules.required,rules.min]"
                                               v-model="password"
                                               label="Nouveau mot de passe"
                                               :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                                               @click:append="showPass=!showPass"
                                               :type="showPass ? 'text' : 'password'"
                                               solo/>
                                   </v-row>
                                   <v-row>
                                       <v-text-field
                                               :rules="[rules.required,rules.min]"
                                               v-model="confirm_password"
                                               label="Confirmer mot de passe"
                                               :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                                               @click:append="showPass=!showPass"
                                               :type="showPass ? 'text' : 'password'"
                                               solo
                                       />
                                   </v-row>
                                   <div  style="text-align:center;margin-top: 15px"  class="field-wrapper">
                                       <v-btn
                                               class="ma-2"
                                               :loading="submitInProgress"
                                               :disabled="submitInProgress || !formIsValid || !verified"
                                               color="primary"
                                               @click.prevent="setPassword">
                                           valider
                                       </v-btn>
                                   </div>
                               </v-form>
                            <div class="text-center">
                                <p class="mb-0 text-muted">Vous avez un compte? <router-link class="f-w-400" :to="{name:'login'}">Me connecter </router-link></p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- end row -->
    </div>
</template>

<script>
    import ValidationErrors from "@/components/ValidationErrors";
    export default {
        name: "PasswordReset",
        components: {ValidationErrors},
        data() {
            return {
                showPass:false,
                formIsValid:false,
                email: null,
                verified:false,
                password: null,
                validationErrors:undefined,
                confirm_password: null,
                submitInProgress: false,
                loginError: undefined,
                token:undefined,
                rules: {
                    required: v => !!v ||  "Ce champ est requis",
                    min: v => !!v && v.length >= 8 || "Le mot de passe doit contenir au moins 8 caractères",
                    match: v => !!v && this.password >= 8 || "Le mot de passe doit contenir au moins 8 caractères",
                    email_rule: v =>  !!v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  "Email invalide",
                },
            }
        },
        mounted(){
            let module = this.$route.name==='invitation'?"auth/verify":"auth/find";

            this.token = this.$route.params.token;
            this.verify(module)

        },
        methods: {
            verify (module) {
                    this.$store.dispatch(module, this.token).then((user) => {
                        this.verified=true;
                        this.email = user.username || user.email
                    }).catch((error)=>{
                        switch (error.response.status) {
                            case 500 :
                                this.$swal('Erreur','Oups! une erreur est survenue, veuillez réssayer','error')
                                break;
                            case 404 :
                                this.validationErrors = {errors:[{errors:error.response.data.error}]}
                                break;
                            default:
                                this.validationErrors = error.response.data
                                break;
                        }
                    });
            },
            setPassword () {
                let data = new FormData();
                data.append("email",this.email);
                data.append("password",this.password);
                data.append("password_confirmation",this.confirm_password);
                    this.submitInProgress = true;
                    this.$store.dispatch("auth/resetPassword", data).then(() => {
                        let message = this.$route.name==='invitation'?"Mot de passe configuré avec succès. Vous pouvez vous connecter avec votre email":
                            "Mot de passe réinitialisé avec succès";
                        this.$swal('Succès',message,'success')
                        .then((value)=>{
                            window.location = "/";
                        })
                    }).catch((error)=>{
                        this.submitInProgress = false;
                        this.loginError = "Email ou mot de passe incorrect";
                        this.validationErrors = error.response.data
                        //this.toast("error", this.loginError)

                    });
            },
        },
    }
</script>

<style scoped>

</style>