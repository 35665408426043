<template>
    <!-- Menu -->
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
        <div class="app-brand demo">
            <a href="/" class="app-brand-link">
                <!--<img src="/assets/img/logos/logo.jpg" alt="" style="width: 100%">-->
                LOTRIPAM
            </a>

            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                <i class="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
        </div>

        <div class="menu-inner-shadow"></div>

        <ul class="menu-inner py-1">
            <!-- Dashboard -->
            <li class="menu-item"  v-if="userHasPermission('dashboard')" @click="currentMenu='home'" :class="currentMenu==='home'?'active':''">
                <router-link :to="{name:'home'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-home-circle"></i>
                    <div data-i18n="Analytics">Tableau de bord</div>
                </router-link>
            </li>
            <li class="menu-item"  v-if="userHasPermission('manage-all')" @click="currentMenu='users.index'" :class="currentMenu==='users.index'?'active':''">
                <router-link :to="{name:'users.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bxs-user-account"></i>
                    <div data-i18n="Analytics">Utilisateurs</div>
                </router-link>
            </li>
            <li class="menu-item"  v-if="userHasPermission('manage-enterprise')" @click="currentMenu='users.index'" :class="currentMenu==='users.index'?'active':''">
                <router-link :to="{name:'users.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bxs-user-account"></i>
                    <div data-i18n="Analytics">Vendeurs</div>
                </router-link>
            </li>
            <li class="menu-item"  v-if="!userHasPermission('manage-all')" @click="currentMenu='customers.index'" :class="currentMenu==='customers.index'?'active':''">
                <router-link :to="{name:'customers.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-group"></i>
                    <div data-i18n="Analytics">Clients</div>
                </router-link>
            </li>
            <li class="menu-item" v-if="userHasPermission('manage-all')"  @click="currentMenu='company.index'" :class="currentMenu==='company.index'?'active':''">
                <router-link :to="{name:'company.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-buildings"></i>
                    <div data-i18n="Analytics">Entreprises</div>
                </router-link>
            </li>
            <li class="menu-item"  v-if="userHasPermission('manage-all')" @click="currentMenu='types.index'" :class="currentMenu==='types.index'?'active':''">
                <router-link :to="{name:'types.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-cog"></i>
                    <div data-i18n="Analytics">Type de jeux</div>
                </router-link>
            </li>
            <li class="menu-item" v-if="userHasPermission('manage-all')"  @click="currentMenu='categories.index'" :class="currentMenu==='categories.index'?'active':''">
                <router-link :to="{name:'categories.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-cog"></i>
                    <div data-i18n="Analytics">Catégorie de jeux</div>
                </router-link>
            </li>
            <li class="menu-item" v-if="userHasPermission('manage-enterprise')" @click="currentMenu='games.index'" :class="currentMenu==='games.index'?'active':''">
                <router-link :to="{name:'games.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-game"></i>
                    <div data-i18n="Analytics">Jeux</div>
                </router-link>
            </li>
          <li class="menu-item" v-if="userHasPermission('manage-all')" @click="currentMenu='licences.index'" :class="currentMenu==='licences.index'?'active':''">
                <router-link :to="{name:'licences.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-card"></i>
                    <div data-i18n="Analytics">Licences</div>
                </router-link>
            </li>
            <li class="menu-item"  v-if="!userHasPermission('manage-all')" @click="currentMenu='games.played'" :class="currentMenu==='games.played'?'active':''">
                <router-link :to="{name:'games.played'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bxs-shapes"></i>
                    <div data-i18n="Analytics">Ventes</div>
                </router-link>
            </li>
            <li class="menu-item" v-if="!userHasPermission('manage-all')" @click="currentMenu='gains.index'" :class="currentMenu==='gains.index'?'active':''">
                <router-link :to="{name:'gains.index'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-book-reader"></i>
                    <div data-i18n="Analytics">Gains</div>
                </router-link>
            </li>
            <li class="menu-item" @click="currentMenu='tirages'" :class="currentMenu==='tirages'?'active':''">
                <router-link :to="{name:'tirages'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-atom"></i>
                    <div data-i18n="Analytics">Tirages</div>
                </router-link>
            </li>
            <li class="menu-item" v-if="userHasPermission('manage-enterprise')" @click="currentMenu='configs'" :class="currentMenu==='configs'?'active':''">
                <router-link :to="{name:'configs'}" class="menu-link">
                    <i class="menu-icon tf-icons bx bx-layer"></i>
                    <div data-i18n="Analytics">Configurations</div>
                </router-link>
            </li>
          <li class="menu-item" v-if="userHasPermission('manage-enterprise')" @click="currentMenu='profile'" :class="currentMenu==='profile'?'active':''">
            <router-link :to="{name:'profile'}" class="menu-link">
              <i class="menu-icon tf-icons bx bx-cog"></i>
              <div data-i18n="Analytics">Paramètres</div>
            </router-link>
          </li>
          </ul>
    </aside>
    <!-- / Menu -->
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "RigthSidebar",
        data(){
            return {
                currentMenu:''
            }
        },
        computed:{
            ...mapState('auth',['userData'])
        },
        mounted() {
            this.currentMenu= this.$route.name

            let menu, animate;

            (function () {
                // Initialize menu
                //-----------------

                let layoutMenuEl = document.querySelectorAll('#layout-menu');
                layoutMenuEl.forEach(function (element) {
                    menu = new Menu(element, {
                        orientation: 'vertical',
                        closeChildren: false
                    });
                    // Change parameter to true if you want scroll animation
                    window.Helpers.scrollToActive((animate = false));
                    window.Helpers.mainMenu = menu;
                });

                // Initialize menu togglers and bind click on each
                let menuToggler = document.querySelectorAll('.layout-menu-toggle');
                menuToggler.forEach(item => {
                    item.addEventListener('click', event => {
                        event.preventDefault();
                        window.Helpers.toggleCollapsed();
                    });
                });

                // Display menu toggle (layout-menu-toggle) on hover with delay
                let delay = function (elem, callback) {
                    let timeout = null;
                    elem.onmouseenter = function () {
                        // Set timeout to be a timer which will invoke callback after 300ms (not for small screen)
                        if (!Helpers.isSmallScreen()) {
                            timeout = setTimeout(callback, 300);
                        } else {
                            timeout = setTimeout(callback, 0);
                        }
                    };

                    elem.onmouseleave = function () {
                        // Clear any timers set to timeout
                        document.querySelector('.layout-menu-toggle').classList.remove('d-block');
                        clearTimeout(timeout);
                    };
                };
                if (document.getElementById('layout-menu')) {
                    delay(document.getElementById('layout-menu'), function () {
                        // not for small screen
                        if (!Helpers.isSmallScreen()) {
                            document.querySelector('.layout-menu-toggle').classList.add('d-block');
                        }
                    });
                }

                // Display in main menu when menu scrolls
                let menuInnerContainer = document.getElementsByClassName('menu-inner'),
                    menuInnerShadow = document.getElementsByClassName('menu-inner-shadow')[0];
                if (menuInnerContainer.length > 0 && menuInnerShadow) {
                    menuInnerContainer[0].addEventListener('ps-scroll-y', function () {
                        if (this.querySelector('.ps__thumb-y').offsetTop) {
                            menuInnerShadow.style.display = 'block';
                        } else {
                            menuInnerShadow.style.display = 'none';
                        }
                    });
                }

                // Init helpers & misc
                // --------------------

                // Init BS Tooltip
                const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
                tooltipTriggerList.map(function (tooltipTriggerEl) {
                    return new bootstrap.Tooltip(tooltipTriggerEl);
                });

                // Accordion active class
                const accordionActiveFunction = function (e) {
                    if (e.type == 'show.bs.collapse' || e.type == 'show.bs.collapse') {
                        e.target.closest('.accordion-item').classList.add('active');
                    } else {
                        e.target.closest('.accordion-item').classList.remove('active');
                    }
                };

                const accordionTriggerList = [].slice.call(document.querySelectorAll('.accordion'));
                const accordionList = accordionTriggerList.map(function (accordionTriggerEl) {
                    accordionTriggerEl.addEventListener('show.bs.collapse', accordionActiveFunction);
                    accordionTriggerEl.addEventListener('hide.bs.collapse', accordionActiveFunction);
                });

                // Auto update layout based on screen size
                window.Helpers.setAutoUpdate(true);

                // Toggle Password Visibility
                window.Helpers.initPasswordToggle();

                // Speech To Text
                window.Helpers.initSpeechToText();

                // Manage menu expanded/collapsed with templateCustomizer & local storage
                //------------------------------------------------------------------

                // If current layout is horizontal OR current window screen is small (overlay menu) than return from here
                if (window.Helpers.isSmallScreen()) {
                    return;
                }

                // If current layout is vertical and current window screen is > small

                // Auto update menu collapsed/expanded based on the themeConfig
               // window.Helpers.setCollapsed(true, false);
            })();

        },
        methods : {
            itemClicked(menu){
                this.currentMenu = menu;

            },
            dropMenu(menu){
                this.currentMenu = menu
                switch (menu) {
                    case "users.index":
                        if (this.getUserRole()==="admin"){
                            this.$router.push({name:"users.index"})
                        }else{
                            this.$router.push({name:"deliverers.index"})
                            this.currentMenu="deliverers.index"
                        }
                        break;
                        case "products-categories.index":
                        if (this.getUserRole()==="admin"){
                            this.$router.push({name:"products-categories.index"})
                        }else{
                            this.$router.push({name:"products.index"})
                            this.currentMenu="products.index"
                        }
                        break;
                }
            }
        }
    }
</script>
<style scoped>

</style>