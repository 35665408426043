export default function () {
    return {
        data() {
            return {
                formIsValid: false,
                validationErrors: undefined,
                previewImage: undefined,
                item: {},
                rules: {
                    name: v => !!v || "Le nom de la ville est requis",
                    description: v => !!v || "Le nom de la ville est requis",
                    image: v => ['image/png', 'image/jpeg', 'image/bmp'].includes(v.type) || "Format de l'image incorrect",
                    imageSize: v => v.size < (2 * 1024 * 1024) || "taille du fichier maximale (2M)",
                }
            }
        },

        methods: {
            init(module) {
                this.get(module)
            },

            get(module) {
                this.$store.dispatch(`${module}/get`, module).then()
            },

            store(module) {
                if (module === "games"){
                    this.item.time =  this.item.time.includes(':00')?this.item.time:this.item.time+":00"
                    this.item.normal_time =  this.item.normal_time.includes(':00')?this.item.normal_time:this.item.normal_time+":00"
                }

                this.$store.dispatch(`${module}/store`, this.item).then(() => {
                    this.submitting = false;
                    this.init(module);
                    this.$swal('Enrégistrement', 'Ajout effectué avec succès', 'success')
                    this.close();
                }).catch((error) => {
                    this.submitting = false;
                    if (error.response){
                        switch (error.response.status) {
                            case 500 :
                                this.$swal('Enrégistrement', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                                break;
                            case 422:
                                this.validationErrors = error.response.data
                                break;
                        }
                    }
                });
            },
            save(module) {
                this.submitting = true;
                if (this.update)
                    this.updateItem(module);
                else
                    this.store(module)
            },
            updateItem(module) {
                if (module === "games"){
                    this.item.time =  this.item.time.includes(':00')?this.item.time:this.item.time+":00"
                    this.item.normal_time =  this.item.normal_time.includes(':00')?this.item.normal_time:this.item.normal_time+":00"
                }

                this.$store.dispatch(`${module}/update`, this.item).then(() => {
                    this.submitting = false;
                    this.init(module);
                    this.$swal('Mise à jour', 'Mise à jour effectué avec succès', 'success')
                    this.close();
                }).catch((error) => {
                    this.submitting = false;
                    switch (error.response.status) {
                        case 500 :
                            this.$swal('Mise à jour', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                            break;
                        case 422:
                            this.validationErrors = error.response.data
                            break;
                    }
                });
            },
            edit(item) {
                this.item = item
                this.dialog = true;
                this.update = true;
            },
            destroy(item, module) {
                this.$swal({
                    title: 'Suppression', text: `Etes vous sûr(e) de vouloir supprimé ${item.name}`,
                    icon: 'warning', showCancelButton: true,
                    confirmButtonText: 'Oui,supprimer', cancelButtonText: "Annuler"
                })
                    .then((value => {
                        if (value.isConfirmed) {
                            this.$store.dispatch(`${module}/destroy`, item.id).then(() => {
                                this.init(module);
                                this.$swal('Suppression', 'Suppression effectuée avec succès', 'success')
                                this.close();
                            }).catch((error) => {
                                switch (error.response.status) {
                                    case 500 :
                                        this.$swal('Mise à jour', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                                        break;
                                    case 424 :
                                        this.$swal('Supression Impossible', error.response.data.message, 'error')
                                        break;
                                }
                            });
                        }
                    }))
            },
            editItemAction(item, index, module) {
                switch (index) {
                    case 0:
                        this.edit(item);
                        break;
                    case 1:
                        this.destroy(item, module)
                        break;

                }
            }


        }
    }
}

