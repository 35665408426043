import {edit, get, create, store, update, destroy} from "@/services/resources";
import {CLIENTS} from "@/services/apis";

export  const clients = {
    namespaced:true,
    state:{
        clients:undefined,
    },
    mutations: {
        SET_CLIENTS(state,payload){
            state.clients = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        get({commit},module){
            return new Promise((resolve,reject)=>{
                get(module).then((response)=>{
                    commit('SET_CLIENTS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit}){
            return new Promise((resolve,reject)=>{
                create(CLIENTS).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(CLIENTS,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(CLIENTS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update({commit},data){
            return new Promise((resolve,reject)=>{
                update(CLIENTS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy({commit},itemId){
            return new Promise((resolve,reject)=>{
                destroy(CLIENTS,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
