import {edit, get, create, store, update, destroy, updateFormData} from "@/services/resources";
import {TYPES} from "@/services/apis";

export  const types = {
    namespaced:true,
    state:{
        types:undefined,
    },
    mutations: {
        SET_TYPES(state,payload){
            state.types = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        get({commit},module){
            return new Promise((resolve,reject)=>{
                get(module).then((response)=>{
                    commit('SET_TYPES',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit}){
            return new Promise((resolve,reject)=>{
                create(TYPES).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(TYPES,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(TYPES,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update({commit},data){
            return new Promise((resolve,reject)=>{
                update(TYPES,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy({commit},itemId){
            return new Promise((resolve,reject)=>{
                destroy(TYPES,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
