<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <div>
      <v-card>
        <div class="d-flex justify-content-end" v-if="userHasPermission('manage-enterprise')">
          <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="dialog = true">
            <v-icon left
                    dark>
              mdi-plus
            </v-icon>
            Tirage du jour
          </v-btn>
        </div>
        <div class="d-flex justify-content-end">
          <v-col md="4">
            <v-text-field solo
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Rechercher"
                          single-line
                          hide-details
            ></v-text-field>
          </v-col>
        </div>
        <v-data-table
            :headers="userHasPermission('manage-enterprise')?headers:headersAll"
            :items="tirages"
            loading-text="Chargement des données"
            :search="search">

          <template v-slot:item.game="{item}">
            {{ item.game.name + ' ' + item.game.type.name }}
          </template>
          <template v-slot:item.balls="{item}">
            <v-chip v-for="(ball,index) in item.balls" color="primary" style="margin: 2px" :key="ball+'-'+index">
              {{ ball }}
            </v-chip>
          </template>
          <template v-slot:item.lotto3_number="{item}">
            <v-chip color="primary">{{ item.lotto3_number }}</v-chip>
          </template>
          <template v-slot:item.validated_at="{item}">
            <v-chip v-if="item.validated_at" color="success">validé</v-chip>
            <div v-else>
              <v-btn small :loading="validating && validatingItem.id===item.id" @click="validate(item)">Valider</v-btn>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-menu
                  key="text"
                  rounded="b-xl">
                <template v-slot:activator="{ attrs, on }">

                  <v-icon
                      class="black--text "
                      v-bind="attrs"
                      v-on="on">mdi-dots-vertical
                  </v-icon>

                </template>
                <v-list>
<!--                  <v-list-item :disabled="(action_item.title==='Modifier' && item.validated_at!=null)"-->
                  <v-list-item
                      v-for="(action_item, index) in actions_items"
                      :key="index"
                      link
                      @click="editItemAction(item,index,'tirages')">
                     <v-list-item-icon>
                        <v-icon color="primary">{{ action_item.avatar }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="action_item.title"></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:no-data>
            <span>Aucune donnée a affichée</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-overlay :value="false">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- Dialog start -->
    <v-dialog v-model="dialog"
              persistent
              max-width="1000px">

      <v-card>
        <v-btn class="float-end m-1" color="transparent" @click="close()">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <span class="text-h5">Tirage du jour</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formIsValid" class="">
            <div class="bg-white p-4">
              <validation-errors v-if="validationErrors" :error="validationErrors"/>
              <v-progress-linear
                  v-if="submitting"
                  indeterminate
                  color="primary"
              ></v-progress-linear>

              <div class="d-flex justify-content-start games-container">
                <v-btn rounded color="primary" v-for="game in games" :key="game.id" :outlined="item.game_id!==game.id"
                       class="m-2" @click="item.game_id = game.id">{{ game.name + ' ' + game.type.name }}
                </v-btn>
              </div>
              <div style="font-size: 2em!important;color: #ED733E" class="d-flex justify-content-center mt-2 mb-2" id="clock"></div>
              <h6 class="text-center ">Selectionnez les boules qui sont tiré aujourd'hui</h6>
              <ball-selection :show-selected-balls="false" :blacklisted-balls="[]"/>
              <div class="row" style="margin-top: 10px">
                <h6 class="text-center mt-2">Selectionnez le numéro de tirage pour la combinaison 3</h6>
                <div class="d-flex justify-content-start games-container">
                  <v-btn v-for="number in lotto3Numbers" :key="'ball-nom-'+number"
                         @click="lotto3Number=number"
                         class="ma-2"
                         small
                         :outlined="number.toString()!=lotto3Number"
                         fab
                         color="primary">
                    {{ number }}
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-2">
              <div class="col-md-10">
                <v-text-field v-model="item.drawn_at" solo label="Date du tirage" type="datetime-local"/>
              </div>
            </div>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              :loading="submitting"
              :disabled="!(item.game_id && lotto3Number!==undefined && selectedBalls.length>=3) || submitting"
              @click="save()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog end -->
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import globalMixins from "@/mixins/globalMixins";
import ValidationErrors from "@/components/ValidationErrors";
import BallSelection from "@/components/balls/BallSelection";
import crudMixin from "@/mixins/crudMixin";

export default {
  name: "Tirages",
  components: {BallSelection, ValidationErrors,},
  mixins: [crudMixin(this), globalMixins(this)],
  data() {
    return {
      tab: '',
      validating: false,
      validatingItem: {},
      headers: [
        {
          text: 'Jeu',
          align: 'start',
          value: 'game.name',
        },
        {
          text: 'Type',
          align: 'start',
          value: 'game.type.name',
        },
        {text: "Boules", value: 'balls'},
        {text: "Lotto 3", value: 'lotto3_number'},
        {text: 'Etat', value: 'validated_at'},
        {text: 'Date', value: 'date'},
        {text: 'Actions', value: 'actions'},
      ],
      headersAll: [
        {
          text: 'Jeu',
          align: 'start',
          value: 'game.name',
        },
        {
          text: 'Type',
          align: 'start',
          value: 'game.type.name',
        },
        {text: "Boules", value: 'balls'},
        {text: "Lotto 3", value: 'lotto3_number'},
        {text: 'Date', value: 'date'},
      ],
      search: '',
      lotto3Number:undefined,
      validationErrors: undefined,
      submitting: false,
      dialog: false,
      update: false,
      item: {game_id: ''},
      actions_items: [
        {title: "Modifier", avatar: 'mdi-pencil', permission: ''},
        {title: "Supprimer", avatar: 'mdi-delete', permission: ''},
      ],
    }
  },
  mounted() {
    this.init('tirages')
    this.init('games')
    this.$store.dispatch('balls/resetBalls')
  },
  methods: {
    ...mapActions('balls', [
      'removeCombination',
      'combine',
      'setBalls'
    ]),
    store() {
      this.$store.dispatch(`tirages/store`, this.item).then(() => {
        this.submitting = false;
        this.init('tirages');
        this.$swal('Enrégistrement', 'Ajout effectué avec succès', 'success')
        this.close();
        this.$store.dispatch('balls/resetBalls')
      }).catch((error) => {
        this.submitting = false;
        switch (error.response.status) {
          case 500 :
            this.$swal('Enrégistrement', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
            break;
          case 422:
            this.validationErrors = error.response.data
            break;
        }
      });
    },
    save() {
      this.submitting = true;
      this.item.balls = this.selectedBalls
      this.item.lotto3_number = this.lotto3Number

      if (this.update)
        this.updateItem();
      else
        this.store()
    },
    updateItem() {
      this.$store.dispatch(`tirages/update`, this.item).then(() => {
        this.submitting = false;
        this.init('tirages');
        this.$swal('Mise à jour', 'Mise à jour effectué avec succès', 'success')
        this.close();
      }).catch((error) => {
        this.submitting = false;
        switch (error.response.status) {
          case 500 :
            this.$swal('Mise à jour', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
            break;
          case 422:
            this.validationErrors = error.response.data
            break;
        }
      });
    },
    edit(item) {
      this.item = item
      this.dialog = true;
      this.update = true;
      this.setBalls({type: 'balls', balls: item.balls})
      this.setBalls({type: 'lotto3Number', balls: item.lotto3_number})
    },
    validate(item) {
      this.validating = true;
      this.validatingItem = item;

      this.$swal({
        title: 'Validation', text: `Etes vous sûr(e) de vouloir valider ce tirage?`,
        icon: 'warning', showCancelButton: true,
        confirmButtonText: 'Oui,valider', cancelButtonText: "Annuler"
      })
          .then((value => {
            this.validating = false;
            if (value.isConfirmed) {
              this.$store.dispatch(`tirages/validate`, {id: item.id}).then(() => {
                this.init("tirages");
                this.$swal('Tirage', 'Validation effectuée avec succès', 'success')
                this.close();
              }).catch((error) => {
                this.validating = false;

                switch (error.response.status) {
                  case 500 :
                    this.$swal('Tirage', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                    break;
                }
              });
            }
          }))
    }
  },
  computed: {
    ...mapState('tirages', ['tirages']),
    ...mapState('games', ['games']),
    ...mapState('balls', [
      'balls',
      'selectedBalls',
      'lotto3Numbers',
      //'lotto3Number',
      'lotto3Balls'
    ]),
  }

}
</script>

<style>
.tel-input {

}
</style>