<template>
    <div class="container-fluid flex-grow-1 container-p-y">
        <div>

            <div class="d-flex justify-content-start games-container">
                <v-btn rounded color="primary" v-for="(game,index) in games" :key="game.id" :outlined="item.game_id!==game.id" class="m-2" @click="selectGame(game.id)">{{game.name +' '+game.type.name}}</v-btn>
            </div>
            <div>
                <v-tabs v-model="tab" grow>
                    <v-tab>Boules</v-tab>
                    <v-tab>Lotto 3</v-tab>
                    <v-tab>Lotto 4</v-tab>
                    <v-tab>Marriage</v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <div class="card bg-white p-4">
                                <div class="row">
                                    <h4 class="col-md-10 d-flex align-self-center">
                                        Contrôler les boule à rétirer du jeux
                                    </h4>
                                    <div class="col-md-1">
                                        <v-switch
                                                v-model="item.control_balls"
                                                inset
                                                label=""
                                        ></v-switch>
                                    </div>
                                    <div v-if="item.control_balls">
                                        <ball-selection :blacklisted-balls="[]"/>
                                        <div class="row">
                                            <h6 class="col-md-8 d-flex align-self-center">
                                              Gain Maximal Autorisé sur une ball
                                            </h6>
                                            <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                                                <v-text-field v-model="item.balls_max_mise" type="number" solo/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="card bg-white p-4">
                                <div class="row">
                                    <h4 class="col-md-10 d-flex align-self-center">
                                        Contrôler les combinaison lotto 3
                                    </h4>
                                    <div class="col-md-1">
                                        <v-switch
                                                v-model="item.control_lotto3"
                                                inset
                                                label=""
                                        ></v-switch>
                                    </div>
                                    <div v-if="item.control_lotto3">
                                        <ball-selection :blacklisted-balls="[]" type="pLotto3Balls"/>
                                        <lotto3-selection type="pLotto3Balls" :game-id="item.game_id"/>
                                        <div class="row">
                                            <h6 class="col-md-8 d-flex align-self-center">
                                              Gain Maximal Autorisé pour un lotto 3
                                            </h6>
                                            <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                                                <v-text-field v-model="item.lotto3_max_mise" type="number" solo/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="card bg-white p-4">
                                <div class="row">
                                    <h4 class="col-md-10 d-flex align-self-center">
                                        Contrôler les combinaisons lotto 4
                                    </h4>
                                    <div class="col-md-1">
                                        <v-switch
                                                v-model="item.control_lotto4"
                                                inset
                                                label=""
                                        ></v-switch>
                                    </div>
                                    <div v-if="item.control_lotto4">
                                        <ball-selection :blacklisted-balls="[]" type="pLotto4Balls"/>
                                        <lotto4-selection type="pLotto4Balls" :game-id="item.game_id"/>
                                        <div class="row">
                                            <h6 class="col-md-8 d-flex align-self-center">
                                              Gain Maximal Autorisé pour un lotto4
                                            </h6>
                                            <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                                                <v-text-field v-model="item.lotto4_max_mise" type="number" solo/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="card bg-white p-4">
                                <div class="row">
                                    <h4 class="col-md-10 d-flex align-self-center">
                                        Contrôler les marriages
                                    </h4>
                                    <div class="col-md-1">
                                        <v-switch
                                                v-model="item.control_marriage"
                                                inset
                                                label=""
                                        ></v-switch>
                                    </div>
                                    <div v-if="item.control_marriage">
                                        <ball-selection :blacklisted-balls="[]" type="pMarriageBalls"/>
                                        <marriage-selection type="pMarriageBalls" :game-id="item.game_id"/>
                                        <div class="row">
                                            <h6 class="col-md-8 d-flex align-self-center">
                                              Gain Maximal Autorisé pour un marriage
                                            </h6>
                                            <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                                                <v-text-field v-model="item.marriage_max_mise" type="number" solo/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>

                <div class="d-flex justify-content-end mt-4">
                    <v-btn color="primary" @click="updateConfig" :loading="submitting">Enrégitré</v-btn>
                </div>
            </div>

        </div>
        <v-overlay :value="false">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import globalMixins from "@/mixins/globalMixins";
    import ValidationErrors from "@/components/ValidationErrors";
    import {VueTelInput} from 'vue-tel-input'
    import BallSelection from "@/components/balls/BallSelection";
    import MarriageSelection from "@/components/balls/MarriageSelection";
    import Lotto3Selection from "@/components/balls/Lotto3Selection";
    import Lotto4Selection from "@/components/balls/Lotto4Selection";
    import crudMixin from "@/mixins/crudMixin";

    export default {
        name: "Configuration",
        components: {Lotto4Selection, Lotto3Selection, MarriageSelection, BallSelection, ValidationErrors, VueTelInput},
        mixins: [globalMixins(this),crudMixin(this)],
        data() {
            return {
                tab: '',
                validationErrors: undefined,
                submitting: false,
                dialog: false,
                update: false,
                item: {
                    game_id:'',
                    control_balls:false,
                    control_marriage:false,
                    control_lotto3:false,
                    control_lotto4:false},
                configs : {}
            }
        },
        mounted() {
            this.$store.dispatch('balls/resetBalls')
            this.$store.dispatch('games/get',"games")
            this.$store.dispatch('gameConfigs/get').then((response)=>{
                if (response.data){
                    this.init(response.data,this.games[0].id)
                }
            })
        },
        methods: {
            init(allConfigs,gameId){
                let configs = allConfigs.find((config)=>gameId === config.game_id)
                this.resetBalls()
                if (configs){
                    this.item = configs
                    this.setBalls({type:'balls',balls:this.item.balls})
                    this.setBalls({type:'lotto3',balls:this.item.lotto3_balls})
                    this.setBalls({type:'lotto4',balls:this.item.lotto4_balls})
                    this.setBalls({type:'marriage',balls:this.item.marriage_balls})
                }else{
                    this.item = {game_id: gameId}
                }
            },
            ...mapActions('balls',[
                'setBalls',
                'resetBalls'
            ]),
            updateConfig() {
                this.submitting = true;
                this.item.balls = this.selectedBalls
                this.item.lotto3_balls = this.lotto3Balls
                this.item.lotto4_balls = this.lotto4Balls
                this.item.marriage_balls = this.marriageBalls
                this.$store.dispatch(`gameConfigs/store`, this.item).then((response) => {
                    this.submitting = false;
                    this.$swal('Configuration', 'Configurations mises à jour avec succès', 'success')
                }).catch((error) => {
                    this.submitting = false;
                    switch (error.response.status) {
                        case 500 :
                            this.$swal('Configuration', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                            break;
                        case 422:
                            this.validationErrors = error.response.data
                            break;
                    }
                });

            },
            selectGame(gameId){
                this.init(this.gameConfigs,gameId)
            }
        },
        computed: {
            ...mapState('balls', [
                'balls',
                'selectedBalls',
                'lotto3Balls',
                'lotto4Balls',
                'marriageBalls'
            ]),
            ...mapState('games',['games']),
            ...mapState('gameConfigs',['gameConfigs'])
        }

    }
</script>

<style>
    .tel-input {

    }
</style>