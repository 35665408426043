import {edit, get,create,store,update,destroy,disableUser} from "@/services/resources";
import {STAFF} from "@/services/apis";

export  const staff = {
    namespaced:true,
    state:{
        staff:undefined,
        createData:undefined,
        editData:undefined,

    },
    mutations: {
        SET_STAFF(state,payload){
            state.staff = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        get({commit}){
            return new Promise((resolve,reject)=>{
                get(STAFF).then((response)=>{
                    commit('SET_STAFF',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit},module){
            return new Promise((resolve,reject)=>{
                create(module).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(STAFF,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(STAFF,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        storeHos({commit},data){
            return new Promise((resolve,reject)=>{
                store(HOS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        storeDeliverers({commit},data){
            return new Promise((resolve,reject)=>{
                store(DELIVERERS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update({commit},data){
            return new Promise((resolve,reject)=>{
                update(STAFF,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy({commit},itemId){
            return new Promise((resolve,reject)=>{
                destroy(STAFF,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        disableUser({commit},userId){
            return new Promise((resolve,reject)=>{
                disableUser(userId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
