<template>
    <div class="container-fluid flex-grow-1 container-p-y">
        <div v-if="userData">
            <div class="d-flex justify-content-center" style="">
                <a @click.prevent="triggerFileInput('logo')">
                    <v-img :src="previewImage" width="200" aspect-ratio="1" class="w-px-60 h-auto rounded-circle"/>
                </a>
            </div>
            <br><br>
            <div>
                <template>
                    <div class="bg-white">
                        <v-stepper v-model="step" alt-labels>
                            <v-progress-linear
                                    v-if="submitting"
                                    indeterminate
                                    color="primary"
                            ></v-progress-linear>
                            <validation-errors v-if="validationErrors" :error="validationErrors"/>
                            <v-stepper-header>
                                <v-stepper-step
                                        :rules="step===1?[() => infosForm]:[]"
                                        :complete="step>1"
                                        step="1">
                                    <div class="text-center">Informations personnelles</div>
                                    <small></small>
                                </v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step
                                        :rules="step===2?[() => proForm]:[]"
                                        :complete="step>2"
                                        step="2">
                                    <div class="text-center">Informations professionnelles</div>
                                    <small></small>
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content :step="1">
                                    <v-form v-model="infosForm">
                                        <div class="d-none">

                                            <v-file-input id="logo" solo
                                                          ref="logo"
                                                          accept="image/png, image/jpeg, image/bmp"
                                                          placeholder="Pick an avatar"
                                                          append-icon="mdi-camera"
                                                          prepend-icon=""
                                                          show-size
                                                          label="Selectionnez une image"
                                                          @change="fileChange()"
                                            ></v-file-input>
                                        </div>

                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Nom</label>
                                                <v-text-field solo
                                                              label="Nom"
                                                              v-model="item.lastname"
                                                              required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Prénom</label>
                                                <v-text-field solo
                                                              label="Prénom"
                                                              v-model="item.firstname"
                                                              required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Email</label>
                                                <v-text-field solo

                                                              label="Email"
                                                              type="email"
                                                              v-model="item.email"
                                                              required
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Téléphone</label>
                                                <VueTelInput
                                                        class="v-text-field tel-input"
                                                        :defaultCountry="229"
                                                        invalidMsg="Numéro de téléphone invalid"
                                                        @validate="phoneChange"
                                                        v-model="item.phone"
                                                        :autoDefaultCountry="false"></VueTelInput>
                                                <small class="text-danger" v-if="item.phone && !phoneIsValid">Numéro de téléphone
                                                    invalid
                                                    {{phoneIsValid}}</small>
                                            </v-col>
<!--                                            <v-col
                                                    cols="12"
                                                    sm="12" md="4">
                                                <label>Pays</label>
                                                <v-autocomplete v-if="countries"
                                                                solo

                                                                :items="countries"
                                                                v-model="item.country_id"
                                                                :item-text="(r)=>r.name"
                                                                :item-value="(r)=>r.id"
                                                                label="Pays"
                                                ></v-autocomplete>
                                            </v-col>-->
                                            <!--<v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Ville</label>
                                                <v-text-field solo

                                                              label="Votre ville"
                                                              type="text"
                                                              v-model="item.city"
                                                              required
                                                ></v-text-field>
                                            </v-col>-->
                                        </v-row>

                                    </v-form>

                                    <div class="d-flex justify-content-end mt-4">
                                        <v-btn class=" align-self-end" color="primary"
                                               @click="step+=1">
                                            Suivant
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </div>

                                </v-stepper-content>
                                <v-stepper-content :step="2">
                                    <v-form v-model="proForm">
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Nom de l'entreprise</label>
                                                <v-text-field solo
                                                              label="Nom de l'entreprise"
                                                              v-model="item.enterprise.name"
                                                              required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Email de l'entreprise</label>
                                                <v-text-field solo
                                                              label="Email"
                                                              v-model="item.enterprise.email"
                                                              required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Téléphone de l'entreprise</label>
                                              <VueTelInput
                                                  class="v-text-field tel-input"
                                                  :defaultCountry="229"
                                                  invalidMsg="Numéro de téléphone invalid"
                                                  @validate="phoneEnterpriseChange"
                                                  v-model="item.enterprise.telephone"
                                                  :autoDefaultCountry="false"></VueTelInput>
                                              <small class="text-danger" v-if="item.enterprise.telephone && !phoneEnterpriseIsValid">Numéro de téléphone
                                                invalid
                                                {{phoneEnterpriseIsValid}}</small>
                                            </v-col>

                                        </v-row>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Adresse</label>
                                                <v-text-field solo
                                                              label="Adresse"
                                                              v-model="item.enterprise.address"
                                                              required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Slogan</label>
                                                <v-text-field solo
                                                              label="Slogan"
                                                              v-model="item.enterprise.slogan"
                                                              required
                                                ></v-text-field>
                                            </v-col>
                                          <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="4">
                                                <label>Slogan 2</label>
                                                <v-text-field solo
                                                              label="Slogan"
                                                              v-model="item.enterprise.slogan_2"
                                                              required
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>
                                        <div class="d-flex justify-content-between">
                                            <v-btn class=" align-self-end" color="primary"
                                                   @click="step-=1">
                                                <v-icon>mdi-arrow-left</v-icon>
                                                Retour
                                            </v-btn>
                                            <v-btn :loading="submitting" class="align-self-end" color="primary"
                                                   @click="updateProfile()">
                                                Valider
                                            </v-btn>
                                        </div>

                                    </v-form>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </div>
                </template>
            </div>

        </div>
        <v-overlay v-else>
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import globalMixins from "@/mixins/globalMixins";
    import ValidationErrors from "@/components/ValidationErrors";
    import {VueTelInput} from 'vue-tel-input'

    export default {
        name: "Profile",
        components: {ValidationErrors, VueTelInput},
        mixins: [globalMixins(this)],
        data() {
            return {
                validationErrors: undefined,
                previewImage: 'assets/img/avatars/1.png',
                step: 1,
                item: {
                    logo: undefined,
                    enterprise: {name: '', address: '', email: '', telephone: ''}
                },
                infosForm: false,
                proForm: false,
                aboutForm: false,
                tab: '',
                phoneIsValid: false,
                phoneEnterpriseIsValid: false,
                submitting: false,
                dialog: false,
                update: false,

            }
        },
        mounted() {
            this.$store.dispatch('auth/getUserData').then((data) => {
                this.item = data.data.identity
                this.item.phone = data.data.identity.telephone
                this.item.enterprise = data.data.enterprise
                this.item.enterprise_id = data.data.enterprise.id

                if ( data.data.enterprise.logo){
                    this.previewImage =  data.data.enterprise.logo && JSON.parse( data.data.enterprise.logo).logo ? JSON.parse( data.data.enterprise.logo).logo.cropped :this.previewImage
                }

            })
        },
        methods: {
            triggerFileInput(input) {
                $('#' + input).click()
            },
            updateProfile() {
                this.submitting = true;
                let data = new FormData();

                Object.keys(this.item).forEach(key => {
                    if (key === 'enterprise')
                        data.append(key, JSON.stringify(this.item[key]))
                    else {
                        data.append(key, this.item[key])
                    }
                });
                if (this.item.file)
                    data.append("file", this.item.file)

                this.$store.dispatch('auth/updateProfile', data).then((response) => {
                    this.submitting = false;

                    this.$swal('Enrégistrement', 'Ajout effectué avec succès', 'success')
                }).catch((error) => {
                    this.submitting = false;
                    switch (error.response.status) {
                        case 500 :
                            this.$swal('Enrégistrement', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                            break;
                        case 422:
                            this.validationErrors = error.response.data
                            break;
                    }
                })
            }
        },
        computed: {
            ...mapState('auth', ['userData', 'countries']),
        }

    }
</script>

<style>
    .tel-input {

    }
</style>