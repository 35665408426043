<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <h4 class="text-start fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Tableau de bord /</span> Jouer au jeux
    </h4>
    <div class="d-flex justify-content-end">
      <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="dialog = true">
        <v-icon left
                dark>
          mdi-plus
        </v-icon>
        Ajouter un client
      </v-btn>
    </div>
    <div v-if="games">
      <validation-errors v-if="validationErrors" :error="validationErrors"/>
      <v-progress-linear
          v-if="submitting"
          indeterminate
          color="primary"
      ></v-progress-linear>

      <div style="font-size: 2em!important;color: #ED733E" class="d-flex justify-content-center" id="clock"></div>
      <template>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1">
              Jeux
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2">
              Mises
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div>
                <div>

                    <h5 class="text-center">Veuillez choisir le jeux</h5>
                    <div class="text-center games-container">
                      <v-btn :disabled="blackListedGames.includes(game.id)" small rounded color="primary" v-for="(game) in games" :key="game.id"
                             :outlined="item.game_id!==game.id" class="m-2" @click="selectGame(game.id)">
                        {{ game.name + ' ' + game.type.name }}
                      </v-btn>
                    </div>
                </div>
                <div class="d-flex justify-content-center" v-if="configs">
                  <v-btn v-for="ball in configs.balls"
                         :key="'selected-btn-ball-'+ball"
                         class="ma-2"
                         small
                         fab
                         disabled
                         color="dark">
                    {{ ball }}
                  </v-btn>
                </div>

                <v-expansion-panels v-if="item.game_id" v-model="panel" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="grey text-white fw-bold">
                      Boules
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ball-selection v-if="configs" :blacklisted-balls="configs.balls"/>
                      <ball-selection v-else />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="grey text-white fw-bold">
                      Lotto3
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <lotto3-selection :game-id="item.game_id"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="grey text-white fw-bold">
                      Lotto4
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <lotto4-selection :game-id="item.game_id"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="grey text-white fw-bold">
                      Marriage
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <marriage-selection :game-id="item.game_id"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div v-else class="alert alert-warning text-center">Vous devez selectionner un jeu pour continuer</div>
              </div>
              <div class="d-flex justify-content-between mt-4">
                <v-btn outlined @click="$router.go(-1)">
                  Quitter
                </v-btn>
                <v-btn
                    color="primary"
                    @click="step = 2">
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form ref="form" v-model="formIsValid">
                <div class="col-12 d-flex justify-end">
                  <span
                      class="total">TOTAL : {{ (formatAmount(calculateSum(mises_lotto4) + calculateSum(mises_balls) + calculateSum(mises_lotto3) + calculateSum(mises_marriage))) }}</span>
                </div>
                <div class="row">
                  <div v-for="(ball,index) in selectedBalls" class="col-md-3" :key="'selected_ball-'+ball">
                    <v-text-field solo :rules="[rules.required]"
                                  label="Mise"
                                  v-model="mises_balls[index]"
                                  required type="number">
                      <template v-slot:prepend-inner>
                        <v-btn class="ma-2"
                               x-small
                               fab
                               color="primary">
                          {{ ball }}
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </div>
                <div class="row">
                  <div v-for="(ball,index) in lotto3Balls" :key="'lotto3-'+ball" class="col-md-3">
                    <v-text-field solo :rules="[rules.required]"
                                  label="Mise"
                                  v-model="mises_lotto3[index]"
                                  required type="number">
                      <template v-slot:prepend-inner>
                        <v-btn class="ma-2"
                               x-small
                               fab
                               color="primary">
                          {{ ball }}
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </div>
                <div class="row">
                  <div v-for="(ball,index) in lotto4Balls" :key="'lotto4-'+ball" class="col-md-3">
                    <lotto4 :balls="ball"/>
                    <v-text-field solo :rules="[rules.required]"
                                  label="Mise lotto4"
                                  v-model="mises_lotto4[index]"
                                  required type="number">
                    </v-text-field>
                  </div>
                </div>
                <div class="row">
                  <div v-for="(ball,index) in marriageBalls" class="col-md-3" :key="index">
                    <marriage :balls="ball"/>
                    <v-text-field solo :rules="[rules.required]"
                                  label="Mise mariage"
                                  v-model="mises_marriage[index]"
                                  required type="number">
                    </v-text-field>
                  </div>
                </div>
              </v-form>
              <div class="d-flex justify-content-between mt-4">
                <v-btn outlined @click="step-=1">
                  Retour
                </v-btn>
                <v-btn :disabled="submitting" :loading="submitting" color="primary" @click="save">
                  Valider
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- Dialog start -->
    <v-dialog v-model="dialog"
              persistent
              max-width="1000px">

      <v-card>
        <v-btn class="float-end m-1" color="transparent" @click="close()">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <span class="text-h5">Information du client</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formIsValid">

                <div class="d-flex justify-content-between">
                  <v-checkbox
                      v-model="clientExist"
                      label="Le client existe"
                  ></v-checkbox>

                  <v-col md="4" v-if="clientExist">
                    <v-autocomplete
                        @change="selectClient"
                        v-model="item.client_id"
                        :items="clients"
                        :item-text="(client)=>client.lastname+' '+client.firstname"
                        :item-value="(client)=>client.id"
                        dense
                        label="Selectionner le client"
                        solo
                    ></v-autocomplete>
                  </v-col>
                </div>
                <v-row>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <v-text-field solo :rules="[rules.required]"
                                  label="Nom du client"
                                  v-model="item.client.firstname"
                                  required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <v-text-field solo :rules="[rules.required]"
                                  label="Prénom du client"
                                  v-model="item.client.lastname"
                                  required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <VueTelInput
                        class="v-text-field tel-input"
                        :defaultCountry="229"
                        invalidMsg="Numéro de téléphone invalid"
                        @validate="phoneChange"
                        v-model="item.client.telephone"
                        :autoDefaultCountry="false"></VueTelInput>
                  </v-col>
                </v-row>
              </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="dark"
              @click="item.client = {}; dialog = false">
            Annuler
          </v-btn>
          <v-btn
              color="primary"
              @click="dialog = false">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog end -->
  </div>
</template>

<script>
// @ is an alias to /src
import ValidationErrors from "@/components/ValidationErrors";
import crudMixin from "@/mixins/crudMixin";
import globalMixins from "@/mixins/globalMixins";
import {mapState} from "vuex";
import Lotto4 from "@/components/Lotto4";
import Marriage from "@/components/Marriage";
import BallSelection from "@/components/balls/BallSelection";
import Lotto3Selection from "@/components/balls/Lotto3Selection";
import Lotto4Selection from "@/components/balls/Lotto4Selection";
import MarriageSelection from "@/components/balls/MarriageSelection";
import {VueTelInput} from 'vue-tel-input'

export default {
  name: 'PlayGame',
  components: {
    MarriageSelection,
    Lotto4Selection,
    Lotto3Selection,
    BallSelection,
    Marriage,
    Lotto4,
    ValidationErrors,
    VueTelInput
  },
  mixins: [crudMixin(), globalMixins()],
  data() {
    return {
      clientExist: false,
      phoneIsValid: false,
      clientId: '',
      step: 1,
      maxBalls: 5,
      panel: [0],
      lotto3Ball: '',
      lotto4Ball: {first: '', second: ''},
      marriageBall: {first: '', second: ''},
      lotto3Number: '',
      headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: "Type", value: 'type.name'},
        {text: "Catégorie", value: 'category.name'},
        {text: 'Date', value: 'created_at'},
        {text: 'Actions', value: 'actions'},
      ],
      search: "",
      dialog: false,
      formIsValid: false,
      submitting: false,
      balls: [],
      time: "",
      mises_lotto3: [],
      mises_lotto4: [],
      mises_balls: [],
      mises_marriage: [],
      configs: '',
      item: {
        client: {},
        balls: [],
        prices: [],
        lotto3_balls: [],
        lotto4_balls: [],
        marriage_balls: [],
        game_id: '',
      },
      blackListedGames : [],
      validationErrors: undefined,
      update: false,
      hour: "10:30",
      rules: {
        required: v => !!v || "Ce champ est requis",
        player_lastname: v => !!v || "Le nom est requis",
        player_firstname: v => !!v || "Le nom est requis",
        player_telephone: v => !!v || "Le nom est requis",
        name: v => !!v || "Le nom est requis",
        type: v => !!v || "Le type est requis",
        category: v => !!v || "La catégorie est requise",
        price_one: v => !!v || "Le prix est requis",
        price_two: v => !!v || "Le prix est requis",
        price_three: v => !!v || "Le prix est requis",
        price_wedding: v => !!v || "Le prix est requis",
        draw: v => !!v || "Le nombre est requis",

      },
      actions_items: [
        //{ title: "Détails", avatar: 'mdi-eye',permission:'read-orders' },
        {title: "Modifier", avatar: 'mdi-pencil', permission: ''},
        {title: "Supprimer", avatar: 'mdi-delete', permission: ''},
      ],

    }
  },
  mounted() {
    this.init('categories')
    this.init('types')
    this.init('games')
    this.init('clients')
    this.$store.dispatch('gameConfigs/get')
    this.$store.dispatch('balls/resetBalls')

  },
  computed: {
    ...mapState('categories', ['categories']),
    ...mapState('types', ['types']),
    ...mapState('games', ['games']),
    ...mapState('gameConfigs', ['gameConfigs']),
    ...mapState('clients', ['clients']),
    ...mapState('balls', [
      'selectedBalls',
      'lotto3Balls',
      'lotto4Balls',
      'marriageBalls',
    ])
  },
  methods: {
    calculateSum(array) {
      return array.reduce(function (a, b) {
        return parseInt(a) + parseInt(b);
      }, 0)
    },
    selectClient() {
      this.item.client = this.clients.find((c) => c.id === this.item.client_id)
    },
    save() {
      this.item.client.telephone = this.item.telephone
      this.item.balls = this.selectedBalls
      this.item.lotto3_balls = this.lotto3Balls
      this.item.lotto4_balls = this.lotto4Balls
      this.item.marriage_balls = this.marriageBalls
      this.item.mises_balls = this.mises_balls
      this.item.mises_lotto4 = this.mises_lotto4
      this.item.mises_lotto3 = this.mises_lotto3
      this.item.mises_marriage = this.mises_marriage

      this.submitting = true;
      this.$store.dispatch('playeds/store', this.item).then(() => {
        this.submitting = false;
        Object.assign(this.$data, this.$options.data())
        this.$swal('Jeux', 'Jeu enrégistré avec succès', 'success')

        this.$store.dispatch("balls/resetBalls")

      }).catch(error => {
        switch (error.response.status) {
          case 500 :
            this.$swal('Jeux', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
            break;
          case 422:
            this.validationErrors = error.response.data
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });

            break;
        }
      }).finally(() => this.submitting = false)
    },
    remove(element, index) {
      switch (element) {
        case 'lotto3' :
          this.item.lotto3_balls = this.item.lotto3_balls.splice(index, 1)
          break;
        case 'lotto4' :
          break;
        case 'marriage' :
          break;
      }
    }
  }
}
</script>
<style>
.position-relative {
  position: relative;
}

.inputnumber {
  background-color: #F7753E;
  color: white;
  font-weight: bold;
  padding: 0;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 0 !important;
}

.total {
  background: #E3E3E3;
  padding: 5px;
  font-size: 1.5em;
  border-radius: 5px;
  font-weight: bold;
  color: gray;
}
</style>