import {edit, get, create, store, update, destroy, updateFormData} from "@/services/resources";
import {CATEGORIES} from "@/services/apis";
import Vue from 'vue'
import swal from "sweetalert2"

function initialState() {
    return {
        balls_range: [
            {label: "00-10", value: 10},
            {label: "10-20", value: 20},
            {label: "20-30", value: 30},
            {label: "30-40", value: 40},
            {label: "40-50", value: 50},
            {label: "50-60", value: 60},
            {label: "60-70", value: 70},
            {label: "70-80", value: 80},
            {label: "80-90", value: 90},
            {label: "90-99", value: 99},
        ],
        lotto3Numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        lotto3Ball: '',
        lotto4Ball: {first: '', second: ''},
        marriageBall: {first: '', second: ''},
        lotto3Number: '',
        lotto3Balls: [],
        lotto4Balls: [],
        marriageBalls: [],
        selectedBalls: [],
        pLotto3SelectedBalls: [],
        pLotto4SelectedBalls: [],
        pMarriageSelectedBalls: [],
        maxBalls: 125,
        currentBalls: '',
        balls: initBalls(),
        currentRange: 0,
        configs: {}

    }
}

function initBalls() {
    let balls = [];
    for (let i = 0; i < 100; i++) {
        let ball = i;
        if (i < 10) {
            ball = `0${i}`
        }
        balls.push(ball)
    }
    return balls;
}

export const balls = {
    namespaced: true,
    state: initialState,
    mutations: {
        SET_CURRENT_RANGE(state, payload) {
            state.currentRange = payload
        },

        SET_CURRENT_BALLS(state, payload) {
            state.currentBalls = payload
        },
        SET_SELECTED_BALLS(state, payload) {
            state.selectedBalls = payload
        },
        SET_LOTTO3_BALLS(state, payload) {
            state.lotto3Balls = payload
        },
        SET_LOTTO4_BALLS(state, payload) {
            state.lotto4Balls = payload
        },
        SET_MARRIAGE_BALLS(state, payload) {
            state.marriageBalls = payload
        },
        SET_LOTTO_3_NUMBER(state, payload) {
            state.marriageBalls = payload
        },
        SET_INITIAL_STATE(state) {
            const oldState = initialState()
            Object.keys(oldState).forEach(key => {
                state[key] = oldState[key]
            })
        }
    },
    actions: {
        setBalls({commit, state}, data) {
            switch (data.type) {
                case 'balls' :
                    state.selectedBalls = data.balls;
                    break;
                case 'pLotto3Balls' :
                    state.pLotto3SelectedBalls = data.balls;
                    break;
                case 'pLotto4Balls' :
                    state.pLotto4SelectedBalls = data.balls;
                    break;
                case 'pMarriageBalls' :
                    state.pMarriageSelectedBalls = data.balls;
                    break;
                case 'lotto3':
                    state.lotto3Balls = data.balls;
                    break;
                case 'lotto4':
                    state.lotto4Balls = data.balls;
                    break;
                case 'marriage':
                    state.marriageBalls = data.balls;
                    break;
                case 'lotto3Number':
                    state.lotto3Number = data.balls;
                    break;
            }
        },
        selectRange({commit, state}, range) {
            state.currentRange = range
            let currentBalls;
            if (range === 0) {
                currentBalls = state.balls.filter((b) => parseInt(b) <= state.balls_range[state.currentRange].value)
            } else {
                currentBalls = state.balls.filter((b) => parseInt(b) <= state.balls_range[state.currentRange].value && parseInt(b) > state.balls_range[state.currentRange - 1].value)
            }

            commit('SET_CURRENT_BALLS', currentBalls)
        },
        selectBall({commit, state}, data) {

            switch (data.type) {

                case "pLotto3Balls":
                    if (state.pLotto3SelectedBalls.includes(data.ball)) {
                        state.pLotto3SelectedBalls = state.pLotto3SelectedBalls.filter((b) => b !== data.ball)
                    } else {
                        state.pLotto3SelectedBalls.push(data.ball)
                    }
                    break;
                case "pLotto4Balls":
                    if (state.pLotto4SelectedBalls.includes(data.ball)) {
                        state.pLotto4SelectedBalls = state.pLotto4SelectedBalls.filter((b) => b !== data.ball)
                    } else {
                        state.pLotto4SelectedBalls.push(data.ball)
                    }
                    break;
                case "pMarriageBalls":
                    if (state.pMarriageSelectedBalls.includes(data.ball)) {
                        state.pMarriageSelectedBalls = state.pMarriageSelectedBalls.filter((b) => b !== data.ball)
                    } else {
                        state.pMarriageSelectedBalls.push(data.ball)
                    }
                    break;
                default:
                    let ballExist = state.selectedBalls.includes(data.ball)
                    if (ballExist) {
                        state.selectedBalls = state.selectedBalls.filter((b) => b !== data.ball)
                    } else {
                        if (state.selectedBalls.length === state.maxBalls) {
                            swal.fire('Limite atteint', 'Vous avez atteint la limite de boule à selectionner', 'error')
                            return
                        }
                        state.selectedBalls.push(data.ball)
                    }
                    break;
            }


        },
        combine({state, rootState}, data) {
            let configs = rootState.gameConfigs.gameConfigs.find((config) => config.game_id === data.gameId);

            let canCombine = true;
            switch (data.type) {
                case 'lotto3':
                    if (data.ball !== undefined) {
                        data.ball = data.ball.toString();
                        if (data.ball.length === 2) {
                            state.lotto3Ball = data.ball.toString();
                        } else {
                            state.lotto3Number = data.ball.toString();
                        }
                    } else {
                        let combination = state.lotto3Number + '' + state.lotto3Ball;
                        if (state.lotto3Balls.includes(combination)) {
                            canCombine = false;
                            swal.fire("Lotto 3", "Oups! cette combinaison est déjà faites", "error")
                        }

                        if (configs && configs.control_lotto3 && configs.lotto3_balls.includes(combination)) {
                            canCombine = false;
                            swal.fire("Lotto 3", "Oups! cette combinaison n'est pas autorisée", "error")
                        }

                        if (canCombine) {
                            state.lotto3Balls.push(combination)
                        }

                        state.lotto3Number = '';
                        state.lotto3Ball = '';

                    }
                    break;
                case 'lotto4' :

                    if (data.ball) {
                        if (data.position === 'first') {
                            state.lotto4Ball.first = data.ball.toString()
                        } else {
                            state.lotto4Ball.second = data.ball.toString()
                        }
                    } else {
                        let combination = state.lotto4Ball.first + '' + state.lotto4Ball.second;

                        if (state.lotto4Balls.includes(combination)) {
                            canCombine = false;
                            swal.fire("Lotto 4", "Oups! cette combinaison est déjà faites", "error")
                        }

                        if (configs && configs.control_lotto4 && configs.lotto4_balls.includes(combination)) {
                            canCombine = false;
                            swal.fire("Lotto 4", "Oups! cette combinaison n'est pas autorisée", "error")
                        }

                        if (canCombine) {
                            state.lotto4Balls.push(combination)
                        }

                        state.lotto4Ball.first = ''
                        state.lotto4Ball.second = ''
                    }

                    break;
                case 'marriage' :
                    if (data.ball) {
                        if (data.position === 'first') {
                            state.marriageBall.first = data.ball.toString()
                        } else {
                            state.marriageBall.second = data.ball.toString()
                        }
                    } else {
                        let combination = state.marriageBall.first + '' + state.marriageBall.second;

                        if (state.marriageBalls.includes(combination)) {
                            canCombine = false;
                            swal.fire("Marriage", "Oups! cette combinaison est déjà faites", "error")
                        }
                        if (configs && configs.control_marriage && configs.marriage_balls.includes(combination)) {
                            canCombine = false;
                            swal.fire("Marriage", "Oups! cette combinaison n'est pas autorisée", "error")
                        }

                        if (canCombine) {
                            state.marriageBalls.push(combination)
                        }

                        state.marriageBall.first = ''
                        state.marriageBall.second = ''
                    }
                    break;
            }
        },
        removeCombination({state}, data) {
            switch (data.type) {
                case 'lotto3':
                    state.lotto3Balls = state.lotto3Balls.filter((b) => data.ball != b)
                    break;
                case 'lotto4':
                    state.lotto4Balls = state.lotto4Balls.filter((b) => data.ball != b)
                    break;
                case 'marriage':
                    state.marriageBalls = state.marriageBalls.filter((b) => data.ball != b)
                    break;
            }
        },
        resetBalls({commit}) {
            commit('SET_INITIAL_STATE')
        }


    },
    getters: {}
}
