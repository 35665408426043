<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <h4 class="text-start fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Tableau de bord/</span> Clients
    </h4>
    <div v-if="clients">
      <v-card>
        <div class="d-flex justify-content-end">
          <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="dialog = true">
            <v-icon left
                    dark>
              mdi-plus
            </v-icon>
            Ajouter
          </v-btn>
        </div>
        <div class="d-flex justify-content-end">
          <v-col md="4">
            <v-text-field solo
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Rechercher"
                          single-line
                          hide-details
            ></v-text-field>
          </v-col>
        </div>
        <v-data-table
            :headers="headers"
            :items="clients"
            loading-text="Chargement des données"
            :search="search">
          <template v-slot:item.email="{item}">
            <a :href="'mailto:'+item.email" style="color: #3AAA35">
              {{ item.email}}
            </a>
          </template>
          <template v-slot:item.telephone="{item}">
            <v-btn outlined link :href="`tel:${item.telephone}`"
                   color="primary">
              <v-icon>mdi-phone</v-icon>
              {{ item.telephone}}
            </v-btn>
          </template>
          <template v-slot:item.created_at="{item}">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-menu
                  key="text"
                  rounded="b-xl">
                <template v-slot:activator="{ attrs, on }">

                  <v-icon
                      class="black--text "
                      v-bind="attrs"
                      v-on="on">mdi-dots-vertical
                  </v-icon>

                </template>
                <v-list>
                  <v-list-item :disabled="action_item.title =='Supprimer' && item.canBeDeleted"
                               v-for="(action_item, index) in actions_items"
                               :key="index"
                               link
                               @click="editItemAction(item,index,'clients')">
                    <v-list-item-icon>
                      <v-icon color="primary">{{ action_item.avatar }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="action_item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:no-data>
            <span>Aucune donnée a affichée</span>
          </template>
        </v-data-table>
      </v-card>

      <!-- Dialog start -->
      <v-dialog v-model="dialog"
                persistent
                max-width="600px">

        <v-card>
          <v-btn class="float-end m-1" color="transparent" @click="close()">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
          <v-card-title>
            <span class="text-h5" v-if="!update">Nouveau client</span>
            <span class="text-h5" v-else>Mise à jour client</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="formIsValid">
              <validation-errors v-if="validationErrors" :error="validationErrors"/>
              <v-progress-linear
                  v-if="submitting"
                  indeterminate
                  color="primary"
              ></v-progress-linear>
              <br>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <v-text-field solo :rules="[rules.lastname]"
                                label="Nom"
                                v-model="item.lastname"
                                required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <v-text-field solo :rules="[rules.firstname]"
                                label="Prénom"
                                v-model="item.firstname"
                                required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <v-text-field solo
                                label="Email"
                                type="email"
                                v-model="item.email"
                                required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <VueTelInput
                      class="v-text-field tel-input"
                      :defaultCountry="229"
                      invalidMsg="Numéro de téléphone invalid"
                      @validate="phoneChange"
                      v-model="item.telephone"
                      :autoDefaultCountry="false"></VueTelInput>
                  <small class="text-danger" v-if="item.telephone && item.telephone.length &&  !phoneIsValid">Numéro de
                    téléphone
                    invalid
                    {{ phoneIsValid }}</small>
                </v-col>
<!--                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <v-text-field solo
                                label="Ville"
                                type="text"
                                v-model="item.city"
                                required
                  ></v-text-field>
                </v-col>-->
<!--                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <v-text-field solo
                                label="Adresse"
                                v-model="item.addresse"
                                required
                  ></v-text-field>
                </v-col>-->

              </v-row>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :loading="submitting"
                :disabled="!phoneIsValid || !formIsValid || submitting"
                @click="save('clients')">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
      <!-- Dialog end -->

    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import ValidationErrors from "@/components/ValidationErrors";
import globalMixins from "@/mixins/globalMixins";
import crudMixin from "@/mixins/crudMixin";
import {mapState} from "vuex";
import {VueTelInput} from 'vue-tel-input'

export default {
  name: "Customers",
  mixins: [globalMixins(), crudMixin()],
  components: {ValidationErrors, VueTelInput},
  data() {
    return {
      headers: [
        {text: 'Nom', value: 'lastname',},
        {text: 'Prénom', value: 'firstname',},
        {text: 'Email', value: 'email'},
        {text: 'Téléphone', value: 'telephone'},
       // {text: 'Ville', value: 'city'},
       // {text: 'Adresse', value: 'address'},
        {text: 'Actions', value: 'actions'},
      ],
      search: "",
      dialog: false,
      formIsValid: false,
      phoneIsValid: false,
      submitting: false,
      item: {},
      validationErrors: undefined,
      update: false,
      rules: {
        firstname: v => !!v || "Le prénom est requis",
        lastname: v => !!v || "Le nom  est requis",
        sexe: v => !!v || "Le sexe est requis",
        tel: v => !!v || "Le numéro de téléphone est requis",
        city: v => !!v || "La ville est requise",
        address: v => !!v || "L'adresse de l'entreprise est requise",
      },
      actions_items: [
        //{ title: "Détails", avatar: 'mdi-eye',permission:'read-orders' },
        {title: "Modifier", avatar: 'mdi-pencil', permission: ''},
        {title: "Supprimer", avatar: 'mdi-delete', permission: ''},
      ],
    }
  },
  mounted() {
    this.init('clients')
  },
  computed: {
    ...mapState('clients', ['clients'])
  }
}
</script>

<style scoped>

</style>