<template>
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="text-start fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Tableau de bord/</span> Catégorie de jeux
        </h4>
        <div v-if="categories">
            <v-card>
                <div class="d-flex justify-content-end">
                    <v-btn
                            color="primary"
                            class="ma-2 white--text"
                            @click="dialog = true">
                        <v-icon left
                                dark>
                            mdi-plus
                        </v-icon>
                        Ajouter
                    </v-btn>
                </div>
                <div class="d-flex justify-content-end">
                    <v-col md="4">
                        <v-text-field solo
                                      v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Rechercher"
                                      single-line
                                      hide-details
                        ></v-text-field>
                    </v-col>
                </div>
                <v-data-table
                        :headers="headers"
                        :items="categories"
                        loading-text="Chargement des données"
                        :search="search">
             <template v-slot:item.created_at="{item}">
                        {{ formatDate(item.created_at)}}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div>
                            <v-menu
                                    key="text"
                                    rounded="b-xl">
                                <template v-slot:activator="{ attrs, on }">

                                    <v-icon
                                            class="black--text "
                                            v-bind="attrs"
                                            v-on="on">mdi-dots-vertical
                                    </v-icon>

                                </template>
                                <v-list>
                                    <v-list-item
                                            v-for="(action_item, index) in actions_items"
                                            :key="index"
                                            link
                                            @click="editItemAction(item,index,'categories')">
                                        <v-list-item-icon>
                                            <v-icon color="primary">{{action_item.avatar}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="action_item.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <span>Aucune donnée a affichée</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- Dialog start -->
            <v-dialog v-model="dialog"
                      persistent
                      max-width="400px">

                <v-card>
                    <v-btn class="float-end m-1"  style="background-color: transparent" @click="close()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                    <v-card-title>
                        <span class="text-h5">Nouvelle catégorie</span>
                    </v-card-title>
                    <v-card-text>
                        <validation-errors v-if="validationErrors" :error="validationErrors"/>
                        <v-progress-linear
                                v-if="submitting"
                                indeterminate
                                color="primary"
                        ></v-progress-linear>
                        <br>
                        <v-form ref="form" v-model="formIsValid">
                            <v-row>
                                <v-col cols="12"
                                        sm="12"
                                        md="12">
                                    <v-text-field solo :rules="[rules.name]"
                                                  label="Nom"
                                                  v-model="item.name"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       sm="12"
                                       md="12">
                                    <v-textarea solo
                                                  label="Description"
                                                  v-model="item.description"
                                                  required
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                :loading="submitting"
                                :disabled="!formIsValid || submitting"
                                @click="save('categories')">
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
            <!-- Dialog end -->

        </div>
        <v-overlay v-else>
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ValidationErrors from "@/components/ValidationErrors";
    import crudMixin from "@/mixins/crudMixin";
    import globalMixins from "@/mixins/globalMixins";
    import {mapState} from "vuex";
    import {CATEGORIES} from "@/services/apis";
    export default {
        name: 'Categories',
        components: {ValidationErrors},
        mixins:[crudMixin(),globalMixins()],
        data() {
            return {
                headers: [
                    {
                        text: 'Nom',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: "Description", value: 'description'},
                    { text: 'Date', value: 'created_at' },
                    {text: 'Actions', value: 'actions'},
                ],
                search: "",
                dialog: false,
                formIsValid: false,
                submitting: false,
                item: {},
                validationErrors: undefined,
                update: false,
                rules:{
                    name: v => !!v ||  "Le nom est requis",
                },
                actions_items: [
                    //{ title: "Détails", avatar: 'mdi-eye',permission:'read-orders' },
                    { title: "Modifier", avatar: 'mdi-pencil',permission:'' },
                    { title: "Supprimer", avatar: 'mdi-delete',permission:'' },
                ],
            }
        },
        mounted() {
            this.init(CATEGORIES)
        },
        computed :{
            ...mapState('categories',['categories'])
        }
    }
</script>
