import Axios from 'axios';
import {BASE_URL} from "../../../env";
Axios.withCredentials = true;
const token = localStorage.getItem('token');
 const client =  Axios.create({
    baseURL: BASE_URL,
    json: true,
    headers: {
        'Authorization': 'Bearer '+token,
    }
});
client.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if(error.response.status===401){
        window.location = "/login";
        localStorage.clear();
    }
    if(error.response.status===403){
        window.location = "/403";
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default client;
