import {edit, get, create, store, update, destroy, updateFormData, validateTirage} from "@/services/resources";
import {TIRAGES} from "@/services/apis";

export  const tirages = {
    namespaced:true,
    state:{
        tirages:undefined,
    },
    mutations: {
        SET_TIRAGES(state,payload){
            state.tirages = [...payload]
        },
    },
    actions: {
        get({commit},module){
            return new Promise((resolve,reject)=>{
                get(module).then((response)=>{
                    commit('SET_TIRAGES',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit}){
            return new Promise((resolve,reject)=>{
                create(TIRAGES).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(TIRAGES,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(TIRAGES,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update({commit},data){
            return new Promise((resolve,reject)=>{
                update(TIRAGES,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy({commit},itemId){
            return new Promise((resolve,reject)=>{
                destroy(TIRAGES,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        validate({commit},data){
            return new Promise((resolve,reject)=>{
                validateTirage(data).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
