import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify);
export default new Vuetify({
    iconfont: "mdi", // 'md' || 'mdi' || 'fa' || 'fa4'
    theme: {
        themes:{
            light: {
                primary: '#ff6a2f',
                secondary: "#3AAA35",
                dark: '#2E2D2C',
                accent: '#006958',
                error: '#b71c1c',
                success: '#3AAA35',
                warning:'#ffad47',
                background: '#ffffff'
            },
        }
    },
});
