/*eslint-disable*/
export default function auth ({store, next}){
    let token = localStorage.getItem('token');
    if(!token){
        return next({
            name: 'login'
        })
    }
    return next();
}
