<template>
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="text-start fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Tableau de bord/</span> Jeux
        </h4>
        <div v-if="games">
            <v-card>
                <div class="d-flex justify-content-end">
                    <v-btn
                            color="primary"
                            class="ma-2 white--text"
                            @click="dialog = true">
                        <v-icon left
                                dark>
                            mdi-plus
                        </v-icon>
                        Ajouter
                    </v-btn>
                </div>
                <div class="d-flex justify-content-end">
                    <v-col md="4">
                        <v-text-field solo
                                      v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Rechercher"
                                      single-line
                                      hide-details
                        ></v-text-field>
                    </v-col>
                </div>
                <v-data-table
                        :headers="headers"
                        :items="games"
                        loading-text="Chargement des données"
                        :search="search">
                    <!-- <template v-slot:item.image="{item}">
                         <img :src="item.image.path.original" width="100" alt="">
                     </template>-->


                    <template v-slot:item.created_at="{item}">
                        {{ formatDate(item.created_at)}}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div>
                            <v-menu
                                    key="text"
                                    rounded="b-xl">
                                <template v-slot:activator="{ attrs, on }">

                                    <v-icon
                                            class="black--text "
                                            v-bind="attrs"
                                            v-on="on">mdi-dots-vertical
                                    </v-icon>

                                </template>
                                <v-list>
                                    <v-list-item
                                            v-for="(action_item, index) in actions_items"
                                            :key="index"
                                            link
                                            @click="editItemAction(item,index,'games')">
                                        <v-list-item-icon>
                                            <v-icon color="primary">{{action_item.avatar}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="action_item.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <span>Aucune donnée a affichée</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- Dialog start -->
            <v-dialog v-model="dialog"
                      persistent
                      max-width="800px">

                <v-card>
                    <v-btn class="float-end m-1"  color="transparent" @click="close()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                    <v-card-title>
                        <span class="text-h5">Nouveau Jeux</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="formIsValid">
                          <validation-errors v-if="validationErrors" :error="validationErrors"/>
                          <br>
                            <v-progress-linear
                                    v-if="submitting"
                                    indeterminate
                                    color="primary"
                            ></v-progress-linear>

                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                    <v-text-field solo :rules="[rules.name]"
                                                  label="Nom du jeux"
                                                  v-model="item.name"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                        sm="4"
                                        md="4">
                                    <v-select solo
                                                  label="Type du jeux"
                                              :items="types"
                                              :item-value="(i)=>i.id"
                                              :item-text="(i)=>i.name"
                                                  v-model="item.game_type_id"
                                                  required
                                    ></v-select>
                                </v-col>
                                <v-col cols="12"
                                       sm="4"
                                       md="4">
                                    <v-select solo
                                              :items="categories"
                                              :item-value="(c)=>c.id"
                                              :item-text="(c)=>c.name"
                                              label="Catégorie du jeux"
                                              v-model="item.game_category_id"
                                              required
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                  <label>Coefficient tirage 1</label>
                                    <v-text-field solo
                                                  label="Coefficient tirage 1"
                                                  v-model="item.first_gain"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                  <label>Coefficient tirage 2</label>
                                  <v-text-field solo
                                                  label="Coefficient tirage 2"
                                                  v-model="item.second_gain"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                  <label>Coefficient tirage 3</label>
                                  <v-text-field solo
                                                  label="Coefficient tirage 3"
                                                  v-model="item.third_gain"
                                                  required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                  <label>Coefficient lotto3</label>

                                  <v-text-field solo
                                                  label="Coefficient lotto3"
                                                  v-model="item.lotto3_gain"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                  <label>Coefficient lotto4</label>
                                  <v-text-field solo
                                                  label="Coefficient lotto4"
                                                  v-model="item.lotto4_gain"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                  <label>Coefficient mariage</label>
                                  <v-text-field solo
                                                  label="Coefficient mariage"
                                                  v-model="item.marriage_gain"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                  <label>Heure de fermeture</label>
                                  <v-text-field solo
                                                  label="Heure de fermeture"
                                                  v-model="item.time"
                                                  type="time"
                                                  required
                                    ></v-text-field>
                                </v-col>
                              <v-col
                                        cols="12"
                                        sm="4"
                                        md="4">
                                <label>Heure du tirage</label>
                                <v-text-field solo
                                                  label="Heure du tirag"
                                                  v-model="item.normal_time"
                                                  type="time"
                                                  required
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-card-text>
                    <v-card-actions style="padding-bottom: 10px">
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                :loading="submitting"
                                :disabled="!formIsValid || submitting"
                                @click="save('games')">
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog end -->

        </div>
        <v-overlay v-else>
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    // @ is an alias to /src

    import ValidationErrors from "@/components/ValidationErrors";
    import crudMixin from "@/mixins/crudMixin";
    import globalMixins from "@/mixins/globalMixins";
    import {mapState} from "vuex";
    export default {
        name: 'Games',
        components: {ValidationErrors},
        mixins:[crudMixin(),globalMixins()],
        data() {
            return {
                headers: [
                    {
                        text: 'Nom',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: "Type", value: 'type.name'},
                    {text: "Catégorie", value: 'category.name'},
                    //{ text: 'Date', value: 'created_at' },
                  { text: 'Heure de fermeture', value: 'time' },
                  { text: 'Heure du tirage', value: 'normal_time' },
                    {text: 'Actions', value: 'actions'},
                ],
                search: "",
                dialog: false,
                formIsValid: false,
                submitting: false,
                item: {},
                validationErrors: undefined,
                update: false,
                rules:{
                    name: v => !!v ||  "Le nom est requis",
                    type: v => !!v ||  "Le type est requis",
                    category: v => !!v ||  "La catégorie est requise",
                    price_one: v => !!v ||  "Le prix est requis",
                    price_two: v => !!v ||  "Le prix est requis",
                    price_three: v => !!v ||  "Le prix est requis",
                    price_wedding: v => !!v ||  "Le prix est requis",
                    draw: v => !!v ||  "Le nombre est requis",

                },
                actions_items: [
                    //{ title: "Détails", avatar: 'mdi-eye',permission:'read-orders' },
                    { title: "Modifier", avatar: 'mdi-pencil',permission:'' },
                    { title: "Supprimer", avatar: 'mdi-delete',permission:'' },
                ],

            }
        },
        mounted() {
            this.init('categories')
            this.init('types')
            this.init('games')
        },
        computed:{

            ...mapState('categories',['categories']),
            ...mapState('types',['types']),
            ...mapState('games',['games']),
        }
    }
</script>
