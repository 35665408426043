<template>
    <!-- Navbar -->

    <nav
            class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar"
    >
        <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
            </a>
        </div>

        <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
            <!-- Profil -->
            <h3 class="navbar-nav align-items-center" v-if="getUserFullRole()">
              <strong v-if="getUserFullRole().name!=='admin' && getUserEnterprise()">{{getUserEnterprise().name}} <sup><v-chip x-small>{{getUserEnterprise().enterprise_licence.name}}</v-chip></sup> </strong>
               <strong v-else>{{getUserFullRole().description}} </strong>
            </h3>
            <!-- /Profil -->

            <ul class="navbar-nav flex-row align-items-center ms-auto">
                <!-- User -->
                <li class="nav-item mr-2">
                  <a class="nav-link" @click.prevent="$router.push({name:'home'})">
                    <div>
                      <img src="/assets/img/icons/home.png" alt class="w-px-40 h-auto rounded-circle" />
                    </div>
                  </a>
                </li>
                <li class="nav-item navbar-dropdown dropdown-user dropdown">
                    <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                        <div class="avatar avatar-online">
                            <img src="/assets/img/avatars/profile.png" alt class="w-px-40 h-auto rounded-circle" />
                        </div>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                            <a class="dropdown-item" href="#">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 me-3">
<!--                                        <div class="avatar avatar-online">
                                            <img src="/assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle" />
                                        </div>-->
                                    </div>
                                    <div class="flex-grow-1" v-if="getConnected()">
                                        <span class="fw-semibold d-block">{{getConnected().firstname+' '+getConnected().lastname}}</span>
                                        <small class="text-muted">{{getUserFullRole().name}}</small>
                                    </div>
                                </div>
                            </a>
                        </li>
<!--                        <li>
                            <div class="dropdown-divider"></div>
                        </li>-->
<!--                        <li>
                            <a class="dropdown-item" href="#">
                                <i class="bx bx-user me-2"></i>
                                <span class="align-middle">Mon Profile</span>
                            </a>
                        </li>-->

                        <!--<li>
                            <a class="dropdown-item" href="#">
                        <span class="d-flex align-items-center align-middle">
                          <i class="flex-shrink-0 bx bx-credit-card me-2"></i>
                          <span class="flex-grow-1 align-middle">Billing</span>
                          <span class="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                        </span>
                            </a>
                        </li>-->
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#" @click="logout">
                                <i class="bx bx-power-off me-2"></i>
                                <span class="align-middle">Déconnexion</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!--/ User -->
            </ul>
        </div>
    </nav>

    <!-- / Navbar -->
</template>

<script>
    import {mapState} from "vuex";
    import globalMixins from "@/mixins/globalMixins";

    export default {
        name: "Navbar",
        mixins:[globalMixins(this)],
        computed:{
         ...mapState('',['auth'])
        },
        methods:{
            logout(){
                this.$store.dispatch('auth/logout')
            }
        }
    }
</script>

<style scoped>

</style>