<template>
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card">
                    <div class="card-body p-4">
                        <h4 class="mb-3 f-w-400 text-center">INSCRIPTION</h4>
                        <div class="p-2 mt-4">
                            <v-form ref="form" v-model="formIsValid">
                                <validation-errors v-if="validationErrors" :error="validationErrors"/>
                                <v-progress-linear
                                        v-if="submitting"
                                        indeterminate
                                        color="primary"
                                ></v-progress-linear>
                              <v-row>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12">
                                  <label for="lastname">Nom de votre entreprise</label>
                                  <v-text-field solo id="name" :rules="[rules.required,rules.string]"
                                                label="Nom de votre entreprise"
                                                v-model="item.name"
                                                required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                                <v-row>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="6">
                                        <label for="lastname">Votre nom</label>
                                        <v-text-field solo id="lastname" :rules="[rules.lastname,rules.string]"
                                                      label="Nom"
                                                      v-model="item.lastname"
                                                      required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="6">
                                        <label for="firstname">Votre prénom</label>
                                        <v-text-field solo id="firstname" :rules="[rules.firstname,rules.string]"
                                                      label="Prénom"
                                                      v-model="item.firstname"
                                                      required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                        <label for="email">Adresse email</label>

                                        <v-text-field solo id="email"
                                                      :rules="[rules.email_required,rules.email_rule,rules.string]"
                                                      label="Email"
                                                      type="email"
                                                      v-model="item.email"
                                                      required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="6">
                                        <label for="phone">Numéro de téléphone</label>

                                        <VueTelInput
                                                id="phone"
                                                class="v-text-field tel-input"
                                                :defaultCountry="229"
                                                invalidMsg="Numéro de téléphone invalid"
                                                @validate="phoneChange"
                                                v-model="item.phone"
                                                :autoDefaultCountry="false"></VueTelInput>
                                        <small style="color: #b71c1c" v-if="item.phone && !phoneIsValid">Numéro de téléphone invalid
                                            {{phoneIsValid}}</small>
                                    </v-col>
                                </v-row>
                              <div class="alert alert-info text-center mt-4" style="color: #073A4B">Un mot de passe vous sera envoyé à votre adresse email. Vous, pourrez le modifier plus tard</div>
                                <div class="d-flex justify-content-center">
                                    <v-btn @click="store" color="primary" :loading="submitting" :disabled="!formIsValid || !phoneIsValid">S'inscrire</v-btn>
                                </div>
                            </v-form>
                            <div class="text-center">
                                <!--Pa$$w0rd!-->
                                <p class="mb-0 text-muted">Avez vous déjà un compte? <router-link class="f-w-400" :to="{name:'login'}">Se Connecter </router-link></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- end row -->
    </div>
</template>

<script>
    import ValidationErrors from "@/components/ValidationErrors";
    import {VueTelInput} from 'vue-tel-input'

    export default {
        name: "Subscription",
        components: {ValidationErrors,VueTelInput},
        data() {
            return {
                phoneIsValid: false,
                submitting: false,
                showPass:false,
                formIsValid:false,
                email: null,
                password: null,
                errors: {},
                item: {},
                rules:{
                    required: v => !!v  ||  "Ce champ est requis",
                    min: v => !!v && v.length >= 8 || "Le mot de passe doit contenir au moins 8 caractères",
                    string: v => !!v && typeof v=== 'string' ||  "Valeur invalid",
                    match: v => v === this.item.password  || "Les mots de pass ne correspondent pas",
                    firstname: v => !!v ||  "Le prénom est requis",
                    lastname: v => !!v ||  "Le nom  est requis",
                    telephone: v => !!v ||  "Le numéro de téléphone est requis",
                    email_required: v => !!v ||  "l'email est requis",
                    email_rule: v =>  !!v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  "Email invalide",
                },
                validationErrors: undefined,

            }
        },

        mounted(){
        },
        methods: {
            phoneChange(e){
                this.phoneIsValid = e.valid
                if (e.valid){
                    this.item.telephone =   e.number;
                }
            },
            store(){
              this.submitting = true;
                this.$store.dispatch("auth/register",this.item).then(()=>{
                  this.submitting = false;
                    this.$swal({
                        title:'Inscription',
                        text:'Inscriprion effectué avec succès',
                        icon:'success',
                        confirmButtonText:"Connectez vous",
                        allowOutsideClick:false
                    }).then(()=>{
                        window.location = '/login'
                    })
                }).catch((error)=>{
                  this.submitting = false;
                  switch (error.response.status) {
                        case 500 :
                            this.$swal('Inscription','Oups! une erreur est survenue, veuillez réssayer','error')
                            break;
                        case 422:
                            this.validationErrors = error.response.data
                            break;
                    }
                });
            },
        },
    }
</script>

<style scoped>

</style>