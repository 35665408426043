<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div>
                    <div class="card">
                        <v-progress-linear
                                indeterminate
                                color="primary"
                                v-if="submitInProgress"
                        ></v-progress-linear>
                        <div class="card-body p-4">
                            <div class="text-center">
<!--                                <a href="/" class="mb-1 d-block auth-logo">
                                    <img style="width: 150px" src="/assets/img/logos/logo.jpg" alt=""
                                         class="logo logo-dark">
                                </a>-->
                              <h1 class="text-uppercase text-primary text-center">Lotripam</h1>
                            </div>
                            <div class="text-center">
                                <h5 class="text-primary">Réinitialisation du mot de passe</h5>
                            </div>
                            <div>
                                <validation-errors v-if="validationErrors" :error="validationErrors"/>
                            </div>
                            <div class="p-2 mt-4">
                                <div class="text-center mb-4" role="alert">
                                    Veuillez saisir votre email, nous vous enverrons un lien de réinitialiation de votre
                                    mot de
                                    passe.
                                </div>


                                <v-form ref="form" v-model="formIsValid">
                                    <v-row>
                                        <v-text-field
                                                :rules="[rules.required,rules.email_rule]"
                                                label="Votre addresse email"
                                                type="email"
                                                v-model="email"
                                                solo/>

                                    </v-row>
                                    <div style="text-align:center;margin-top: 15px" class="field-wrapper">
                                        <v-btn
                                                class="ma-2"
                                                :loading="submitInProgress"
                                                :disabled="submitInProgress || !formIsValid"
                                                color="primary"
                                                @click.prevent="send">
                                            Envoyer
                                        </v-btn>
                                    </div>
                                </v-form>
                                <div class="text-center">
                                    <p class="mb-0 text-muted"><router-link class="f-w-400" :to="{name:'login'}">Me connecter </router-link></p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="mt-5 text-center">
                        <p>© 2022 Popote Group</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </div>

</template>

<script>
    import ValidationErrors from "@/components/ValidationErrors";

    export default {
        name: "PasswordForgot",
        components: {ValidationErrors},
        data() {
            return {
                email: undefined,
                errorMessage: undefined,
                successMessage: undefined,
                submitInProgress: false,
                formIsValid: false,
                validationErrors: undefined,
                rules: {
                    required: v => !!v || "Ce champ est requis",
                    email_rule: v => !!v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Email invalide",
                },
            }
        },
        methods: {
            send() {
                let data = new FormData();
                data.append('email', this.email);
                this.submitInProgress = true;
                this.$store.dispatch("auth/sendResetMail",data).then((response) => {
                    this.submitInProgress = false;
                    this.$swal('Succès', response.message, 'success')

                }).catch((error) => {
                    this.submitInProgress = false;
                    switch (error.response.status) {
                        case 500 :
                            this.$swal('Erreur', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                            break;
                            case 404 :
                                this.validationErrors = {errors:[{errors:error.response.data.error}]}
                                break;
                        default:
                            this.validationErrors = error.response.data
                            break;
                    }
                });
            },


        }
    }
</script>

<style scoped>

</style>