import {get, store} from "@/services/resources";

export  const dashboard = {
    namespaced:true,
    state:{
        data:undefined,
    },
    mutations: {
        SET_DATA(state,payload){
            state.data = payload
        },
    },
    actions: {
        get({commit},data){
            return new Promise((resolve,reject)=>{
                store("dashboard",data).then((response)=>{
                    commit('SET_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },


    },
    getters: {

    }
}
