import {edit, get, create, store, update, destroy} from "@/services/resources";
import {GAMES} from "@/services/apis";

export  const games = {
    namespaced:true,
    state:{
        games:undefined,
    },
    mutations: {
        SET_GAMES(state,payload){
            state.games = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        getGameTime({commit}){
            return new Promise((resolve,reject)=>{
                get("game-time").then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        get({commit},module){
            return new Promise((resolve,reject)=>{
                get(module).then((response)=>{
                    commit('SET_GAMES',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit}){
            return new Promise((resolve,reject)=>{
                create(GAMES).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(GAMES,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(GAMES,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update({commit},data){
            return new Promise((resolve,reject)=>{
                update(GAMES,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy({commit},itemId){
            return new Promise((resolve,reject)=>{
                destroy(GAMES,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
