<template>
  <div>
    <admin-dashboard v-if="getUserFullRole() && getUserFullRole().includes('client')"/>
    <seller-dashboard v-if="getUserFullRole() && getUserFullRole().includes('seller')"/>
    <master-admin-dashboard v-if="getUserFullRole() && getUserFullRole().includes('admin')"/>
  </div>
</template>

<script>
// @ is an alias to /src

import AdminDashboard from "@/components/AdminDashboard";
import SellerDashboard from "@/components/SellerDashboard";
import MasterAdminDashboard from "@/components/MasterAdminDashboard";
export default {
  name: 'Home',
  components: {
    MasterAdminDashboard,
    SellerDashboard,
    AdminDashboard
  }
}
</script>
