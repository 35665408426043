import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import './registerServiceWorker'


Vue.use(VueViewer)
const options = {
    confirmButtonColor: '#ff6a2f',
    cancelButtonColor: '#2E2D2C',
};

Vue.use(VueSweetalert2,options);
Vue.config.productionTip = false
Vue.mixin( {
  methods: {
      toast(type,message)
      {
      let vueBOx=this,
          Toast= this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', vueBOx.$swal.stopTimer)
              toast.addEventListener('mouseleave', vueBOx.$swal.resumeTimer)
            }
          });
      Toast.fire({
        icon: type,
        title: message
      })
    },
      momentJs(data)
      {
      var date = new Date((data));
      return moment(date).startOf('hour').fromNow();
    },
      limitText: function (text,length)
      {
      if(text) {
        let mytext = text.slice(0, length);
        (text.length > 12) ? mytext = mytext + ' ...' : '';
        return mytext;
      }else{
        return '...';
      }
    },
      userHasPermission(permission)
      {
        return this.$store.getters["auth/user"] && this.$store.getters["auth/user"].permissions.includes(permission.toString())
      },
      getConnected()
      {
          if (this.$store.getters["auth/user"])
              return this.$store.getters["auth/user"].data.identity;
      },
      getUserFullRole()
      {
          if (this.$store.getters["auth/user"])
              return this.$store.getters["auth/user"].data.roles[0].name
      },
      getUserRole()
      {
          if (this.$store.getters["auth/user"])
              return this.$store.getters["auth/user"].data.roles[0].name
      },
      getUserEnterprise()
      {
          if (this.$store.getters["auth/user"])
              return this.$store.getters["auth/user"].data.enterprise
      }

  }
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
