<template>
    <div>
        <div v-if="selectionType === 'range'" class="m-2 d-flex justify-content-center games-container">
            <v-btn-toggle color="dark">
                <v-btn small @click="selectRange(index)" :outlined="currentRange!==index"
                       v-for="(range,index) in balls_range" :key="'cle-'+index">
                    {{range.label}}
                </v-btn>
            </v-btn-toggle>
        </div>
        <div v-else>

        </div>
    </div>
</template>
<script>
import globalMixins from "@/mixins/globalMixins";
import {mapActions, mapState} from "vuex";

export default {
    name: 'BallRangeSelector',
    mixins: [globalMixins()],
    data(){
      return {
          selectionType : 'range'
      }
    },
    computed : {
        ...mapState('balls', [
            'balls_range',
            'currentRange',
        ]),
    },
    methods : {
        ...mapActions('balls', [
            'selectBall',
            'selectRange'
        ]),
    }
}
</script>