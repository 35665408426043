<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="container">
        <h1>Oups! Vous n'avez pas des autorisations nécéssaire</h1>
        <!-- end row -->

        <div>
            <router-link to="/">Retourner à l'acceuil</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "403",
        data() {
            return { }
        },
        computed:{},
        mounted(){
        },
    }
</script>

<style scoped>

</style>