<template>
    <div class="d-flex justify-content-between" style="background: lightgrey;margin: 5px;border-radius: 15px;align-items: center">
        <v-btn
                class="ma-2"
                x-small
                fab

                color="primary">
            {{formatCombination(balls)[0]}}
        </v-btn>
        &
        <v-btn
                class="ma-2"
                x-small
                fab
                color="primary">
            {{formatCombination(balls)[1]}}
        </v-btn>
    </div>
</template>

<script>
    import globalMixins from "@/mixins/globalMixins";

    export default {
        name: "Lotto4",
        props:['balls'],
        mixins:[globalMixins()]
    }
</script>

<style scoped>

</style>