<template>
    <div>
        <h3 class="text-center mt-4">Boule de combinaison</h3>
        <div class="d-flex justify-content-end mt-4">
            <v-btn small color="primary"
                   v-if="lotto3Number.length>0  && lotto3Ball.length>0"
                   @click="combine({type:'lotto3',gameId:gameId})">
                combiner
            </v-btn>
        </div>
        <div class="row" style="align-items: center">
            <div class="d-flex justify-content-sm-start justify-content-md-center games-container">
                <v-btn v-for="number in lotto3Numbers" :key="'ball-nom-'+number"
                       @click="combine({type:'lotto3',ball:number,gameId:gameId})"
                       class="ma-2"
                       small
                       :outlined="number.toString()!=lotto3Number"
                       fab
                       color="primary">
                    {{number}}
                </v-btn>
            </div>
            <ball-range-selector/>
            <div class="d-flex justify-content-sm-start justify-content-md-center games-container">
                <v-btn v-for="ball in ballsType==='balls'?currentBalls:pLotto3SelectedBalls" :key="'selected-ball-'+ball"
                       class="ma-2"
                       small
                       :outlined="ball.toString()!=lotto3Ball"
                       fab
                       @click="combine({type:'lotto3',ball:ball,gameId:gameId})"
                       color="primary">
                    {{ball}}
                </v-btn>
            </div>
            <div class="d-flex justify-content-center"
                 v-if="lotto3Balls.length>0">
                <v-btn v-for="ball in lotto3Balls" :key="'lotto_3-'+ball"
                       class="ma-2"
                       small
                       fab
                       @click="removeCombination({type:'lotto3',ball:ball})"
                       color="primary">
                    {{ball}}
                </v-btn>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import BallRangeSelector from "@/components/balls/BallRangeSelector.vue";

    export default {
        name: "Lotto3Selection",
        components: {BallRangeSelector},
        props:["blacklistedBalls","type","gameId"],
        computed: {
            ...mapState('balls', [
                'selectedBalls',
                'pLotto3SelectedBalls',
                'currentBalls',
                'lotto3Ball',
                'lotto3Number',
                'lotto3Numbers',
                'lotto3Balls',
            ]),
            ballsType(){
                return this.type?this.type:'balls'
            }
        },
        methods: {
            ...mapActions('balls', [
                'removeCombination',
                'combine',
            ])
        }
    }
</script>

<style scoped>

</style>