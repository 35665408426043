<template>
  <div>
    <div class="container-xxl flex-grow-1 container-p-y"  v-if="data || submitting ">
      <div class="d-flex justify-content-center mb-4">
        <a href="#" class="btn card col-md-4 mb-2" @click="showStats = !showStats">
          <v-img :src="`assets/img/icons/analytics.png`" alt="" width="50" style="margin: auto" />
          Consulter les Statistiques
        </a>
      </div>
      <div v-if="showStats">
        <div class="row d-flex justify-content-center" v-if="games">
          <div class="col-md-4">
            <v-select solo
                      @change="gameSelected"
                      :items="[...[{id : 'all',name : 'Tout les jeux',type : {name:''}}],...games]"
                      :item-value="(c)=>c.id"
                      :item-text="(c)=>c.name +' '+c.type.name"
                      label="Jeux"
                      v-model.trim="item.game_id"
                      required
            ></v-select>
          </div>
          <div class="col-md-4">
            <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field solo
                              v-model="item.dates"
                              label="Période"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="item.dates"
                  no-title
                  @change="dateSelected()"
                  range>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="dateMenu = false">
                  Annuler
                </v-btn>
                <v-btn text
                       color="primary"
                       @click="$refs.menu.save(item.dates)">
                  Valider
                </v-btn>
              </v-date-picker>
            </v-menu>

          </div>

        </div>
        <div class="row">
          <div class="col-md-6 col-lg-3 col-xl-3 order-0 mb-4">
            <div class="card h-100">
              <div class="card-header d-flex align-items-center justify-content-between pb-0">
                <div class="card-title mb-0">
                  <h5 class="m-0 me-2">Nombre de tickets </h5>
                </div>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex flex-column align-items-center gap-1">
                    <h2 class="mb-0">{{data.total_sells}}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 col-xl-3 order-0 mb-4">
            <div class="card h-100">
              <div class="card-header d-flex align-items-center justify-content-between pb-0">
                <div class="card-title mb-0">
                  <h5 class="m-0 me-2">Chiffre d'affaires </h5>
                </div>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex flex-column align-items-center gap-1">
                    <h2 class="mb-0">{{formatAmount(data.total_sells_amount)}}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 col-xl-3 order-0 mb-4">
            <div class="card h-100">
              <div class="card-header d-flex align-items-center justify-content-between pb-0">
                <div class="card-title mb-0">
                  <h5 class="m-0 me-2">Profit/Perte </h5>
                </div>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex flex-column align-items-center gap-1">
                    <h2 class="mb-0">{{formatAmount(data.total_sells_amount - (data.total_commission + data.total_gains))}}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 col-xl-3 order-0 mb-4">
            <div class="card h-100">
              <div class="card-header d-flex align-items-center justify-content-between pb-0">
                <div class="card-title mb-0">
                  <h5 class="m-0 me-2">Commission </h5>
                </div>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="d-flex flex-column align-items-center gap-1">
                    <h2 class="mb-0">{{formatAmount(data.total_commission)}}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dashboard-menu/>

    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
    import {mapState} from "vuex";
    import globalMixins from "@/mixins/globalMixins";
    import DashboardMenu from "@/components/DashboardMenu.vue";

    export default {
        name: "SellerDashboard",
      components: {DashboardMenu},
      mixins:[globalMixins()],
      data(){
        return {
          showStats:false,
          dateMenu:"",
          submitting : false,
          seller:undefined,
          item: {game_id : 'all',dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)]},
        }
      },
        computed:{
           ...mapState('dashboard',['data']),
          ...mapState('games', ['games']),

        },
        mounted() {
          this.$store.dispatch('games/get','games').then(()=>{})

          this.init();

        },
        methods:{
          init(){
            this.$store.dispatch('dashboard/get',this.item).then(()=>{
              this.submitting = false;

            })
          },
          gameSelected(value) {
            this.item.game_id = value;
            this.submitting = true;
            this.init()
          },
          dateSelected() {
            this.submitting = true;
            this.init()
          },

        }
    }
</script>

<style scoped>

</style>