<template>
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="text-start fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Tableau de bord/</span>Entreprises
        </h4>
        <div>
            <v-card>
                <div class="d-flex justify-content-end">
                    <v-btn
                            color="primary"
                            class="ma-2 white--text"
                            @click="dialog = true">
                        <v-icon left
                                dark>
                            mdi-plus
                        </v-icon>
                        Ajouter
                    </v-btn>
                </div>
                <div class="d-flex justify-content-end">
                    <v-col md="4">
                        <v-text-field solo
                                      v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Rechercher"
                                      single-line
                                      hide-details
                        ></v-text-field>
                    </v-col>
                </div>
                <v-data-table
                        :headers="headers"
                        :items="enterprises"
                        loading-text="Chargement des données"
                        :search="search">
                     <template v-slot:item.logo="{item}">
                         <img :src="item.logo.path.original" width="100" alt="">
                     </template>
                    <template v-slot:item.active_sellers="{item}">
                        <v-chip>{{item.active_sellers?item.active_sellers.length:0}}</v-chip>
                    </template>
                  <template v-slot:item.created_at="{item}">
                        {{ formatDate(item.created_at)}}
                    </template>
                  <template v-slot:item.status="{item}">
                    <v-switch  v-if="item.manager" @click="changeUserStatus(item.manager)" color="primary"
                              :input-value="(item.manager.disabled_at==null  )?'true':''">
                    </v-switch>
                  </template>
                    <template v-slot:item.actions="{ item }">
                        <div>
                            <v-menu
                                    key="text"
                                    rounded="b-xl">
                                <template v-slot:activator="{ attrs, on }">

                                    <v-icon
                                            class="black--text "
                                            v-bind="attrs"
                                            v-on="on">mdi-dots-vertical
                                    </v-icon>

                                </template>
                                <v-list>
                                    <v-list-item
                                            v-for="(action_item, index) in actions_items"
                                            :key="index"
                                            link
                                            @click="editItemAction(item,index)">
                                        <v-list-item-icon>
                                            <v-icon color="primary">{{action_item.avatar}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="action_item.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <span>Aucune donnée a affichée</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- Dialog start -->
            <v-dialog v-model="dialog"
                      persistent
                      max-width="600px">

                <v-card>
                    <v-btn class="float-end m-1" outlined icon color="transparent" @click="close()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                    <v-card-title>
                        <span class="text-h5">Entreprise</span>
                    </v-card-title>
                  <validation-errors v-if="validationErrors" :error="validationErrors"/>
                  <br>
                    <v-card-text>
                        <v-form ref="form" v-model="formIsValid">

                            <v-progress-linear
                                    v-if="submitting"
                                    indeterminate
                                    color="primary"
                            ></v-progress-linear>

                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="12"
                                        md="12">
                                    <v-text-field solo :rules="[rules.required]"
                                                  label="Nom de l'entreprise"
                                                  v-model="item.name"
                                                  required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                    <v-text-field solo :rules="[rules.lastname]"
                                                  label="Nom"
                                                  v-model="item.lastname"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                    <v-text-field solo :rules="[rules.firstname]"
                                                  label="Prénom"
                                                  v-model="item.firstname"
                                                  required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                    <v-text-field solo :rules="[rules.email_rule]"
                                                  label="Email"
                                                  type="mail"
                                                  v-model="item.email"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                  <VueTelInput
                                      class="v-text-field tel-input"
                                      :defaultCountry="229"
                                      invalidMsg="Numéro de téléphone invalid"
                                      @validate="phoneChange"
                                      v-model="item.telephone"
                                      :autoDefaultCountry="false"></VueTelInput>
                                  <small class="text-danger" v-if="item.telephone && item.telephone.length && !phoneIsValid">Numéro de téléphone
                                    invalid
                                    {{phoneIsValid}}</small>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select solo label="Type de licence"
                                              :item-text="((l)=>l.name)"
                                              :item-value="((l)=>l.id)"
                                              v-model="item.licence"
                                              :items="licences"/>
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                :loading="submitting"
                                :disabled="!phoneIsValid || !formIsValid || submitting"
                                @click="save('enterprises')">
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog end -->
        </div>
    </div>
</template>

<script>
    import ValidationErrors from "@/components/ValidationErrors";
    import {mapState} from "vuex";
    import crudMixin from "@/mixins/crudMixin";
    import globalMixins from "@/mixins/globalMixins";
    import {VueTelInput} from 'vue-tel-input'

    export default {
        name: "Company",
        mixins:[crudMixin(),globalMixins()],
        components: {ValidationErrors,VueTelInput},
        data() {
            return {
                headers: [
                    {
                        text: 'Nom',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {text: 'Email', value: 'email'},
                    {text: 'Téléphone', value: 'telephone'},
                    {text: 'Vendeurs actifs', value: 'active_sellers'},
                    {text: 'Licence', value: 'enterprise_licence.name'},
                    { text: 'Statut', value: 'status' },
                    {text: 'Actions', value: 'actions'},
                ],
                search: "",
                dialog: false,
                formIsValid: false,
              phoneIsValid: false,
                submitting: false,
                item: {password:"password",password_confirmation:"password"},
                validationErrors: undefined,
                update: false,
                rules:{
                    required: v => !!v ||  "Ce champ  est requis",
                    lastname: v => !!v ||  "Le nom  est requis",
                    firstname: v => !!v ||  "Le prénom  est requis",
                    tel: v => !!v ||  "Le numéro de téléphone est requis",
                    addresse: v => !!v ||  "L'adresse de l'entreprise est requise",
                    city: v => !!v ||  "La ville est requise",
                    email_required: v => !!v ||  "l'émail est requis",
                    email_rule: v =>  !!v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  "Email invalide",
                },
                actions_items: [
                    { title: "Modifier", avatar: 'mdi-pencil',permission:'' },
                ],
            }
        },
        mounted() {
          this.init()
        },
        methods:{
          init(){
            this.$store.dispatch("enterprises/get","enterprises")
            this.$store.dispatch("licences/get","licences")

          },
            edit(item){
                this.item = item
                this.dialog = true;
                this.update = true;
                this.item.firstname = item.manager?item.manager.identity.firstname:''
                this.item.lastname = item.manager?item.manager.identity.lastname:''
                this.item.identity_id = item.manager?item.manager.identity.id:''
            }
        },
        computed : {
            ...mapState('enterprises',['enterprises']),
            ...mapState('licences',['licences']),
        }
    }
</script>

<style scoped>

</style>