import {store,setGainAsPaid} from "@/services/resources";
import {GAINS} from "@/services/apis";

export  const gains = {
    namespaced:true,
    state:{
       gains:undefined,
    },
    mutations: {
        SET_GAINS(state,payload){
            state.gains = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        get({commit},data){
            return new Promise((resolve,reject)=>{
                store(GAINS,data).then((response)=>{
                    commit('SET_GAINS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        setGainAsPaid({commit},id){
            return new Promise((resolve,reject)=>{
                setGainAsPaid(id).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },


    },
    getters: {

    }
}
