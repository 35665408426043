<template>
    <div>
        <BallRangeSelector/>
        <div class="d-flex justify-content-sm-start justify-content-md-center games-container" v-if="showSelectedBalls">
            <span class="alert alert-primary text-center" v-if="!selectedBalls.length">Aucune boule sélectionnée</span>
            <div v-else class="d-flex justify-content-center">
                <div v-if="type==='balls'">
                    <v-btn v-for="ball in selectedBalls"
                           :key="'selected-btn-ball-'+ball" @click="selectBall({type:ballsType,ball:ball})"
                           class="ma-2"
                           small
                           :outlined="!ballIsSelected(ball,getBalls(ballsType))"
                           fab
                           color="primary">
                        {{ball}}
                    </v-btn>
                </div>
                <div v-if="type==='pLotto3Balls'">
                    <v-btn v-for="ball in pLotto3SelectedBalls"
                           :key="'selected-btn-ball-'+ball" @click="selectBall({type:ballsType,ball:ball})"
                           class="ma-2"
                           small
                           :outlined="!ballIsSelected(ball,getBalls(ballsType))"
                           fab
                           color="primary">
                        {{ball}}
                    </v-btn>
                </div>
                <div v-if="type==='pLotto4Balls'">
                    <v-btn v-for="ball in pLotto4SelectedBalls"
                           :key="'selected-btn-ball-'+ball" @click="selectBall({type:ballsType,ball:ball})"
                           class="ma-2"
                           small
                           :outlined="!ballIsSelected(ball,getBalls(ballsType))"
                           fab
                           color="primary">
                        {{ball}}
                    </v-btn>
                </div>
                <div v-if="type==='pMarriageBalls'">
                    <v-btn v-for="ball in pMarriageSelectedBalls"
                           :key="'selected-btn-ball-'+ball" @click="selectBall({type:ballsType,ball:ball})"
                           class="ma-2"
                           small
                           :outlined="!ballIsSelected(ball,getBalls(ballsType))"
                           fab
                           color="primary">
                        {{ball}}
                    </v-btn>
                </div>

            </div>
        </div>
        <div class="d-flex justify-content-sm-start justify-content-md-center games-container">
            <v-btn v-for="ball in currentBalls" :key="'current-ball-'+ball" :disabled="blacklistedBalls && blacklistedBalls.includes(ball)"
                   @click="selectBall({type:ballsType,ball:ball})"
                   class="ma-2"
                   small
                   :outlined="!ballIsSelected(ball,getBalls(ballsType))"
                   fab
                   color="primary">
                {{ball}}
            </v-btn>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import globalMixins from "@/mixins/globalMixins";
import BallRangeSelector from "@/components/balls/BallRangeSelector.vue";

export default {
        name: "BallSelection",
    components: {BallRangeSelector},
        mixins: [globalMixins()],
        props: [
            'showSelectedBalls',
            'blacklistedBalls',
            'type'
        ],
        data() {
            return {}
        },
        mounted() {
        },
        computed: {
            ...mapState('balls', [
                'balls_range',
                'currentRange',
                'selectedBalls',
                'pLotto3SelectedBalls',
                'pLotto4SelectedBalls',
                'pMarriageSelectedBalls',
                'currentBalls'
            ]),
            ballsType(){
                return this.type?this.type:'balls'
            }
        },
        methods: {
            ...mapActions('balls', [
                'selectBall',
                'selectRange'
            ]),
            getBalls() {
                switch (this.ballsType) {
                    case "pLotto3Balls":
                        return this.pLotto3SelectedBalls;
                    case "pLotto4Balls":
                        return this.pLotto4SelectedBalls;
                    case "pMarriageBalls":
                        return this.pMarriageSelectedBalls;
                    default:
                        return this.selectedBalls;
                }
            }
        }
    }
</script>