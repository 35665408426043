<template>
    <div>
        <div class="alert alert-danger justify-content-start">
            <!--<div class="text-center">{{error.message}}</div>-->
            <ul class="list-unstyled">
                <li v-for="errors in error.errors">
                    <ul class="list-unstyled">
                        <li v-for="message in errors">- {{message}}</li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ValidationErrors",
        props:['error']
    }
</script>

<style scoped>

</style>